import {FormikProps} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {fileListToBase64} from '../../../utils/image'
import {InitialValues} from './validationSchema'
import ImgMsg from '../../customTable/ImgMsg'
import {Avatar, Stack} from '@mui/material'
import SizeImage from '../../customTable/SizeImage'
import Image from '../../customTable/Image'
import {useState} from 'react'
import {useSelector} from 'react-redux'

type Props = {
  formik: FormikProps<InitialValues>
  hight?: string
  width?: string
}

export const IamgeSection = ({formik, width, hight}: Props) => {
  const [isWidth, setIsWidth] = useState(false)
  const [isHieght, setIsHieght] = useState(false)
  const {sizeImages} = useSelector((state: any) => state.vender)
  return (
    <div className='card-body text-center pt-0'>
      <div
        className=' image-input-empty image-input-outline image-input-placeholder mb-3'
        data-kt-image-input='true'
      >
        {' '}
        <label
          htmlFor='image'
          className=' btn-active-color-primary  bg-body shadow'
          data-kt-image-input-action='change'
          data-bs-toggle='tooltip'
          title='Change image'
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: (width && isWidth) || (isHieght && hight) ? '1px solid red' : 'none',
              borderRadius: '8px',
            }}
            className='image-input-wrapper w-150px h-150px'
          >
            {' '}
            {formik.values.image ? (
              <Image
                src={
                  typeof formik.values.image === 'object'
                    ? URL.createObjectURL((formik.values.image as any)[0])
                    : formik.values.image
                }
                alt='cser Avatar'
                className='w-150px h-150px'
                sx={{maxWidth: '100%', maxHeight: '100%', borderRadius: '8px'}}
              />
            ) : (
              <Image
                src={toAbsoluteUrl('/media/avatars/img.svg')}
                alt='user-info'
                className='w-100px h-100px'
                sx={{borderRadius: '8px'}}
              />
            )}
          </div>

          {/* <i className='bi bi-pencil-fill fs-7'></i> */}

          <input
            id='image'
            type='file'
            multiple={false}
            style={{display: 'none'}}
            accept='.jpg, .jpeg, .png, .svg'
            onChange={(e) => {
              const fileList = e.target.files as FileList

              // تحقق من وجود الملفات
              if (!fileList || fileList.length === 0) {
                formik.setFieldError('image', 'No file selected')
                return
              }

              // التحقق من العدد
              if (fileList.length > 1) {
                formik.setFieldError('image', 'Please do not take more than 4 items')
                formik.setFieldTouched('image', true)
                e.target.value = '' // Clear the value of the input element
                return
              }

              const file = fileList[0]

              // التحقق من الحجم
              const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2)

              const reader = new FileReader()
              reader.readAsDataURL(file)

              reader.onload = () => {
                const base64 = reader.result as string

                // استخراج الأبعاد
                const img = new window.Image() as HTMLImageElement
                img.src = base64

                img.onload = () => {
                  if (width && sizeImages[width] !== img.width) {
                    setIsWidth(true)
                  }
                  if (hight && sizeImages[hight] !== img.height) {
                    setIsHieght(true)
                  }
                  console.log(`File Size: ${fileSizeInMB} MB`)

                  // تخزين القيمة في formik بعد التأكد
                  formik.setFieldValue('image', base64)
                }
              }

              reader.onerror = (error) => {
                console.error('Error reading file:', error)
                formik.setFieldError('image', 'Error reading file')
              }
            }}
            onFocus={(e) => {
              if (!formik.touched.image) {
                formik.setFieldTouched('image', true)
              }
            }}
            onBlur={(e) => {
              if (!formik.touched.image) {
                formik.setFieldTouched('image', true)
              }
            }}
          />
        </label>
        {/*  check  if  the  formik  image  is  found  then  show  x  icon  */}
      </div>
      <SizeImage width={width} hight={hight} />

      {/* <ImgMsg /> */}
    </div>
  )
}
