import React, {useState} from 'react'
import {ActionsCell} from '../../componet/Menu'
import {Avatar, Stack} from '@mui/material'
import IBanner from '../../interface/IBanner'
import Switch from '../../componet/Toastr/Switch'
import Banner from '../../Api/banner'
import Edit from './Edit'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import SimpleLoading from '../customTable/SimpleLoading'
import PupUpImage from '../customTable/PupUpImage'
import NameText from '../customTable/NameText'
import Image from '../customTable/Image'

export default function Row({
  item,
  i,
  setRows,
  rows,
  setAdded,
  setNum,
}: {
  item: any
  i: number
  rows: any
  setRows: any
  setAdded: any
  setNum: any
}) {
  const [state, setState] = useState(item.status)
  const [open, setOpen] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const [isLoading, setIsLoading] = useState(false)
  const handelEdit = () => {
    setOpen(true)
  }

  const handleStatusChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    Banner.putStatus(
      item.id,
      state === 1 ? 0 : 1,
      () => {
        setRows(
          [
            ...rows.filter((e: any) => e.id != item.id),
            {...editItem, status: state === 1 ? 0 : 1},
          ].sort((a, b) => b.id - a.id)
        )

        setState(state === 1 ? 0 : 1)
      },
      setIsLoading
    )
  }

  const [isDeleted, setDeleted] = useState(true)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const handelDelete = () => {
    setAdded(false)
    Banner.delete(
      item.id,
      () => {
        setNum((prev: any) => prev - 1)
        setAdded(true)
        setRows((prev: any) => prev.filter((e: any) => e.id !== item.id))
      },
      setIsLoadingDelete
    )
  }
  const store = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()

  return (
    <>
      {isDeleted && (
        <>
          {' '}
          <tr className='fw-bold text-muted '>
            <td style={{textAlign: 'center'}}>
              <PupUpImage image={editItem.image}>
                {' '}
                <Stack width={'150px'} height={'136px'}>
                  <Image
                    sx={{width: '100%', height: '100%', borderRadius: '8px'}}
                    src={editItem.image}
                    alt={editItem.title && editItem.title.slice(0, 15)}
                  />
                </Stack>
              </PupUpImage>
            </td>
            <td style={{textAlign: store.isRtl === 0 ? 'start' : 'start'}}>
              <span className='text-muted me-2 fs-7 fw-semibold'>
                <NameText text={editItem.title} />
              </span>
            </td>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted me-2 fs-7 fw-semibold'>{t(editItem.type)}</span>
            </td>{' '}
            <td style={{textAlign: 'start'}}>
              <span className='text-muted me-2 fs-7 fw-semibold'>{t(editItem.section)}</span>
            </td>
            <td style={{textAlign: 'start'}} className='text-start'>
              {isLoading ? (
                <SimpleLoading />
              ) : (
                <Switch
                  name='status'
                  value={state === 1 ? true : false}
                  handleChange={
                    store.permissions &&
                    store.permissions.includes('banners_update') &&
                    handleStatusChange
                  }
                />
              )}{' '}
            </td>{' '}
            <td style={{textAlign: 'start'}} className='text-start'>
              {isLoadingDelete ? (
                <SimpleLoading />
              ) : (
                <ActionsCell
                  onEdit={
                    store.permissions && store.permissions.includes('banners_update') && handelEdit
                  }
                  onDelete={
                    store.permissions &&
                    store.permissions.includes('banners_delete') &&
                    handelDelete
                  }
                  onView={null}
                />
              )}
            </td>
          </tr>
          {open && (
            <Edit
              setRows={setRows}
              rows={rows}
              setItem={setEditItem}
              item={editItem}
              open={open}
              setOpen={setOpen}
            />
          )}
        </>
      )}
    </>
  )
}
