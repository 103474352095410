import {Button, CircularProgress, Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import {useNavigate} from 'react-router-dom'
import PopUp from './PopUp'
import ChangePackagePopUp from './ChangePackagePopUp'

export default function MainBtn({
  isLoading,
  handelSubmit,
  text,
  disabled,
  width,
  p,
  fs,
  isAmount,
  bgcolor,
  type,
  without,
  height,
  secondary,
  br,
  num,
  backageKey,
  color,
  bc,
  minWidth,
}: any) {
  const [open, setOpen] = useState(false)
  const {myPackage} = useSelector((state: RootState) => state.vender)
  const navigate = useNavigate()
  const {t} = useTranslation()

  console.log()

  function capitalizeFirstLetter(text: any) {
    if (!text) return text
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }

  const translatedText = t(text)
  const capitalizedText = capitalizeFirstLetter(translatedText)
  return (
    <Stack>
      <Button
        type={type}
        disabled={disabled || isLoading}
        sx={{
          fontSize: fs || '14px',
          color:
            disabled || isLoading
              ? '#fff !important '
              : color
              ? color
              : without
              ? 'var(--main-color)'
              : secondary
              ? '#129AFC'
              : 'white',
          fontWeight: '500',
          background:
            disabled || isLoading
              ? '#9D9DA6'
              : bgcolor
              ? bgcolor
              : without
              ? '#fff'
              : secondary
              ? 'rgba(18, 154, 252, 0.10)'
              : 'var(--main-color)',
          borderRadius: br || '6px',
          padding: p || '6px 20px',
          border: bc ? `1px solid ${bc}` : without ? '1px solid var(--main-color)' : 'none',
          width: width || '100%',
          minWidth: minWidth || '120px',
          height: height || 'auto',
          textTransform: 'none',
          '&:hover': {
            color: '#f16645',
            border: '1px solid #f16645',
          },
        }}
        onClick={() => {
          if (
            myPackage[backageKey] &&
            myPackage[backageKey].amount &&
            myPackage[backageKey].amount <= num
          ) {
            setOpen(true)
          } else {
            handelSubmit()
          }
        }}
      >
        {isLoading ? (
          <CircularProgress size={'20px'} color='info' />
        ) : isAmount ? (
          translatedText
        ) : (
          capitalizedText
        )}
      </Button>
      {myPackage[backageKey] &&
        myPackage[backageKey].amount &&
        myPackage[backageKey].amount <= num &&
        open && (
          <PopUp width='sm' setOpen={setOpen} open={open}>
            <ChangePackagePopUp />
          </PopUp>
        )}
    </Stack>
  )
}
