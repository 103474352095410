import React, {useEffect, useState} from 'react'
import Tabs from '../../customTable/Tabs'
import {Box, Container, Stack, Typography} from '@mui/material'
import Switch from '../../../componet/Toastr/Switch'
import DateInput from '../../customTable/DateInput'
import LabelInput from '../../customTable/LabelInput'
import RedStart from '../../customTable/RedStart'
import InputType from '../../customTable/InputType'
import SaveButton from '../../customTable/SaveButton'
import CustomSelect from '../../customTable/CustomSelect'
import TextErea from '../../customTable/TextErea'
import Coupons from '../../../Api/coupons'
import MultiSelect from '../../customTable/MultiSelectDropdown'
import Product from '../../../Api/product'
import Catalog from '../../../Api/catalog'
import {Cuoponschema} from '../../../yup/CouponShcema'
import * as Yup from 'yup'

import ICuopon from '../../../interface/ICuopon'
import MySelect from '../../customTable/CustomSelectTest'
import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import TabsWithFlag from '../../customTable/LangTabsWithFlag'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import SimpleLoading from '../../customTable/SimpleLoading'
import CheckInputWithLabel from '../../customTable/CheckInputWithLabel'
import ValidationFun from '../../../yup/ValidationFun'
import TabsLangWithFlag from '../../customTable/LangTabs'

export default function EditForm({
  cuopon,
  setEdit,
  setOpen,
  edit,
  rows,
  setRows,
  isLoading,
  setIsLoading,
}: any) {
  const [activeLang, setActiveLang] = useState('en')

  const [editcuopon, stEditCuopon] = useState<any>(cuopon)
  const [editOldcuopon, stEditOldCuopon] = useState<any>(cuopon)

  const [state, setState] = useState(1)
  const [freeShop, setFreeShop] = useState(1)
  const [startDate, setStartDate] = useState(editcuopon.start_date.split(' ')[0])
  const [endDate, setEndDate] = useState(editcuopon.end_date.split(' ')[0])
  const [isLoadingCatagory, setLoadingCatagory] = useState(false)

  useEffect(() => {
    stEditCuopon(cuopon)
    stEditOldCuopon(cuopon)
    setStartDate(cuopon.start_date && cuopon.start_date.split(' ')[0])
    setEndDate(cuopon.end_date && cuopon.end_date.split(' ')[0])
  }, [cuopon])
  const handleStatusChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setState(state === 1 ? 0 : 1)
    stEditCuopon({...editcuopon, status: state === 1 ? 0 : 1})
  }
  const handleFreeShopChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setFreeShop(freeShop === 1 ? 0 : 1)
    stEditCuopon({...editcuopon, is_free_shipping: freeShop === 1 ? 0 : 1})
  }
  const [msgErr, setMsgErr] = useState('')
  const [isProduct, setIsProduct] = useState(1)
  const [errors, setErrors] = useState<any>({})

  const [optionsDisCount, setDisCount] = useState([
    {id: 0, name: 'Percentage discount'},
    {
      name: 'Fixed cart discount',
      id: 1,
    },
    {
      name: 'Fixed product discount',
      id: 2,
    },
  ])
  const [languages, setlanguages] = useState(['en'])
  const store = useSelector((state: RootState) => state.vender)

  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])
  const onSubmit = () => {
    ValidationFun(
      {
        ...editcuopon,
        start_date: startDate,
        end_date: endDate,
        discount_type:
          editcuopon.discount_type === 'percentage'
            ? 0
            : editcuopon.discount_type === 'fixed_cart'
            ? 1
            : 2,
      },
      Cuoponschema(languages),
      setErrors,
      (value: any) => {
        Coupons.putDetails(
          {
            ...value,
            discount_type:
              value.discount_type === 3 || value.discount_type === 0
                ? 'percentage'
                : value.discount_type === 1
                ? 'fixed_cart'
                : 'fixed_product',
          },
          cuopon.id,
          (res: any) => {
            setEdit(res)
            stEditCuopon(res)
            setRows(
              [
                ...rows.filter((e: any) => e.id != edit.id),
                {
                  ...res,
                },
              ].sort((a, b) => b.id - a.id)
            )
            setOpen(false)
          },
          setIsLoading
        )
      }
    )
  }
  const [products, setProducts] = useState([])
  const [catlogs, setcatlogs] = useState([])
  const [isLoadingProducts, setIsLoadingProducts] = useState(false)

  const getProduct = async () => {
    const fatchProducts = await Product.get((res: any) => {
      setProducts(res.data)
    }, setIsLoadingProducts)
  }
  const getCatlogs = () => {
    Catalog.get((res: any) => {
      setcatlogs(res.data)
    }, setLoadingCatagory)
  }

  useEffect(() => {
    getCatlogs()
    getProduct()
  }, [])

  const {t} = useTranslation()

  const [defLang, setDefLang] = useState('')
  const [selectedLang, setSelectedLang] = useState<any>([])
  const getLangs = async () => {
    setSelectedLang(store.langs)
    setDefLang(
      store.langs.some((e: any) => e.code === store.defLang) ? store.defLang : store.langs[0].code
    )
  }
  useEffect(() => {
    getLangs()
  }, [store.langs])

  return (
    <Container
      lang={store.lang}
      dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
      className='card'
      sx={{padding: '25px 20px', position: 'relative'}}
      maxWidth='md'
    >
      <Stack width={'100%'}>
        <Stack justifyContent={'space-between'} alignItems={'center'} direction='row'>
          <Typography
            sx={{
              fontSize: '19px',
              color: 'var(--bs-heading-color, inherit)',
              fontWeight: 600,
              lineHeight: '24px',
              textTransform: 'capitalize',
            }}
          >
            {t('editCoupon')}
          </Typography>

          <Stack direction={'row'}>
            {/* <Tabs activeLang={activeLang} setActiveLang={setActiveLang} /> */}
          </Stack>
        </Stack>
        <Stack mt='40px' gap='20px' flexWrap={'wrap'} direction={'row'}>
          <Stack position={'relative'} width={{sm: 'calc(50% - 11px)', xs: '100%'}} gap='10px'>
            {selectedLang.map((e: any, i: number) => (
              <>
                {e.code === defLang && (
                  <>
                    <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                      <LabelInput text={t('name')} />
                      <RedStart />
                    </label>
                    <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                      <InputType
                        name={'name'}
                        change={stEditCuopon}
                        value={editcuopon}
                        type='text'
                        child={e.code}
                        errors={errors}
                      />
                      {/* <ErrorMsg msgErr={msgErr} name='address' /> */}
                    </Box>
                  </>
                )}
              </>
            ))}
            <Stack
              position={'absolute'}
              right={store.isRtl === 0 ? '0' : 'auto'}
              left={store.isRtl === 1 ? '0' : 'auto'}
              top='24px'
            >
              <TabsLangWithFlag
                activeLang={defLang}
                setActiveLang={setDefLang}
                languages={selectedLang}
                name={'name'}
                errors={errors}
              />
            </Stack>
          </Stack>

          <Stack sx={{width: {sm: 'calc(50% - 11px)', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'CODE'}>
              <LabelInput text={t('code')} />

              <RedStart />
            </label>
            <Box sx={{width: '100%'}}>
              <InputType
                errors={errors}
                change={stEditCuopon}
                value={editcuopon}
                name={'code'}
                type='text'
              />
            </Box>
          </Stack>
        </Stack>
        <Stack mt='20px' position={'relative'} width={{xs: '100%'}} gap='10px'>
          {selectedLang.map((e: any, i: number) => (
            <>
              {e.code === defLang && (
                <>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                    <LabelInput text={t('description')} />
                    <RedStart />
                  </label>
                  <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                    <TextErea
                      errors={errors}
                      child={e.code}
                      name={'description'}
                      change={stEditCuopon}
                      value={editcuopon}
                    />
                    {/* <ErrorMsg msgErr={msgErr} name='address' /> */}
                  </Box>
                </>
              )}
            </>
          ))}
          <Stack
            position={'absolute'}
            right={store.isRtl === 0 ? '0' : 'auto'}
            left={store.isRtl === 1 ? '0' : 'auto'}
            top='24px'
          >
            <TabsLangWithFlag
              activeLang={defLang}
              setActiveLang={setDefLang}
              languages={selectedLang}
              name={'description'}
              errors={errors}
            />
          </Stack>
        </Stack>

        <Stack mt='20px'>
          <Stack gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'type'}>
              <LabelInput text={t('discountType')} />

              <RedStart />
            </label>
            <Box sx={{minWidth: {sm: '250px', xs: '100%'}}}>
              <MySelect
                values={editcuopon}
                option={optionsDisCount}
                setValues={stEditCuopon}
                name='discount_type'
                value={{
                  id:
                    editcuopon.discount_type === 'percentage'
                      ? 0
                      : editcuopon.discount_type === 'fixed_cart'
                      ? 1
                      : 2,
                }}
              />{' '}
            </Box>
          </Stack>
        </Stack>
        <Stack alignItems={'center'} mt='40px' gap='20px' flexWrap={'wrap'} direction={'row'}>
          <Stack sx={{width: {sm: 'calc(50% - 11px)', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
              <LabelInput text={t('amount')} />

              <RedStart />
            </label>
            <Box sx={{width: '100%'}}>
              <InputType
                errors={errors}
                name={'discount_amount'}
                change={stEditCuopon}
                value={editcuopon}
                type='number'
              />
            </Box>
          </Stack>

          <Stack sx={{width: {sm: 'calc(50% - 11px)', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'CODE'}>
              <Typography
                sx={{
                  fontSize: '19px',
                  color: 'var(--bs-heading-color, inherit)',
                  fontWeight: 700,
                  lineHeight: '24px',
                  textTransform: 'capitalize',
                }}
              >
                {t('allowFreeShepping')}
              </Typography>
              <Box mt='5px'>
                <Switch
                  name='free'
                  value={freeShop === 1 ? true : false}
                  handleChange={handleFreeShopChange}
                />
              </Box>
            </label>
          </Stack>
        </Stack>

        <Stack alignItems={'center'} mt='40px' gap='20px' flexWrap={'wrap'} direction={'row'}>
          <Stack sx={{width: {sm: 'calc(50% - 11px)', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
              <LabelInput text={t('startDate')} />

              <RedStart />
            </label>
            <Box sx={{width: '100%'}}>
              <DateInput
                errors={errors}
                name='start_date'
                date={startDate}
                setDate={setStartDate}
              />
            </Box>
          </Stack>

          <Stack sx={{width: {sm: 'calc(50% - 11px)', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
              <LabelInput text={t('couponExpiryDate')} />

              <RedStart />
            </label>
            <Box sx={{width: '100%'}}>
              <DateInput errors={errors} name='end_date' date={endDate} setDate={setEndDate} />
            </Box>
          </Stack>
        </Stack>
        <Stack alignItems={'center'} mt='40px' gap='20px' flexWrap={'wrap'} direction={'row'}>
          <Stack sx={{width: {sm: 'calc(50% - 11px)', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
              <LabelInput text={t('limit')} />

              <RedStart />
            </label>
            <Box sx={{width: '100%'}}>
              <InputType
                errors={errors}
                name={'usage_limit_per_coupon'}
                change={stEditCuopon}
                value={editcuopon}
                type='number'
              />
            </Box>
          </Stack>

          <Stack sx={{width: {sm: 'calc(50% - 11px)', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
              <LabelInput text={t('limitUsage')} />

              <RedStart />
            </label>
            <Box sx={{width: '100%'}}>
              <InputType
                errors={errors}
                name={'limit_usage_to_x_items'}
                change={stEditCuopon}
                value={editcuopon}
                type='number'
              />
            </Box>
          </Stack>
        </Stack>
        <Stack alignItems={'center'} mt='40px' gap='20px' flexWrap={'wrap'} direction={'row'}>
          <Stack sx={{width: {sm: 'calc(50% - 11px)', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
              <LabelInput text={t('limitPerUser')} />

              <RedStart />
            </label>
            <Box sx={{width: '100%'}}>
              <InputType
                errors={errors}
                name={'usage_limit_per_user'}
                change={stEditCuopon}
                value={editcuopon}
                type='number'
              />
            </Box>
          </Stack>

          <Stack sx={{width: {sm: 'calc(50% - 11px)', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
              <LabelInput text={t('minimumSpend')} />

              <RedStart />
            </label>
            <Box sx={{width: '100%'}}>
              <InputType
                errors={errors}
                name={'min_spend'}
                change={stEditCuopon}
                value={editcuopon}
                type='number'
              />
            </Box>
          </Stack>
        </Stack>

        <Stack alignItems={'center'} mt='40px' gap='20px' flexWrap={'wrap'} direction={'row'}>
          <Stack width='100%' gap='24px' direction={'row'}>
            <CheckInputWithLabel
              onClick={() => {
                setIsProduct(1)
                stEditCuopon({...editcuopon, categories: editOldcuopon.categories})

                // setcatlogs([])
              }}
              check={isProduct}
              value={1}
              text={'products'}
            />
            <CheckInputWithLabel
              onClick={() => {
                stEditCuopon({...editcuopon, products: editOldcuopon.products})
                // setProducts([])
                setIsProduct(0)
              }}
              check={isProduct}
              value={0}
              text={'categories'}
            />
          </Stack>
          {isProduct === 1 && (
            <Stack sx={{width: {xs: '100%'}}} gap='10px'>
              <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
                <LabelInput text={t('products')} />
              </label>
              <Box sx={{width: {xs: '100%'}}}>
                {isLoadingProducts ? (
                  <SimpleLoading />
                ) : (
                  <MultiSelect
                    name={'products'}
                    cuopon={editcuopon}
                    stCuopon={stEditCuopon}
                    products={products}
                  />
                )}

                {/* <InputType errors={errors} type='number' /> */}
              </Box>
            </Stack>
          )}

          {isProduct === 0 && (
            <Stack sx={{width: {xs: '100%'}}} gap='10px'>
              <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
                <LabelInput text={'categories'} />
              </label>
              <Box sx={{width: {xs: '100%'}}}>
                <MultiSelect
                  name={'categories'}
                  cuopon={editcuopon}
                  stCuopon={stEditCuopon}
                  products={catlogs}
                />
              </Box>
            </Stack>
          )}
        </Stack>
        <Stack mt='20px' direction={'row'} alignItems={'flex-end'} gap='100px'>
          <Typography
            sx={{
              fontSize: '19px',
              color: 'var(--bs-heading-color, inherit)',
              fontWeight: 600,
              lineHeight: '24px',
              textTransform: 'capitalize',
            }}
          >
            {t('status')}
          </Typography>
          <Switch
            name='status'
            value={state === 1 ? true : false}
            handleChange={handleStatusChange}
          />
        </Stack>

        <SaveButton
          errors={errors}
          setOpen={setOpen}
          isLoading={isLoading}
          submit={onSubmit}
          text={t('saveChanges')}
        />
      </Stack>
      <Stack alignItems={'flex-end'}></Stack>
    </Container>
  )
}
