import React, {useState, useCallback, useEffect} from 'react'
import HeadTable from '../customTable/HeadTable'
import TableHead from './TablHead'
import Row from './Row'
import Banner from '../../Api/banner'
import {PaginationSection} from '../customTable/pagnationSection'
import {CustomerListLoading} from '../customer/order/custmerListLoading'
import {CircularProgress, Stack} from '@mui/material'
import IBanner from '../../interface/IBanner'
import Manufacturers from '../../Api/manufacturers'
import Search from '../customTable/Search'
import Create from './Create'
import SelectState from './SelectState'
import {useTranslation} from 'react-i18next'
import OrderApi from '../../Api/order'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import PendingIcon from '../../svg/PendingIcon'
import Shipped from '../../svg/Shipped'
import InProgress from '../../svg/InProgress'
import Refund from '../../svg/Refund'
import Commpleted from '../../svg/Commpleted'
import Canceled from '../../svg/Canceled'
import Label from '../customTable/Label'
import NoDataTable from '../customTable/NoDataTable'

export default function Table({rows, setRows, filter}: any) {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [timerId, setTimerId] = useState<number | null>(null)
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(1)
  const [added, setAdded] = useState(false)

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = e.target.value
      setSearch(e.target.value)
      if (timerId) {
        clearTimeout(timerId)
      }

      setTimerId(
        window.setTimeout(() => {
          getCoupons(e.target.value, rowsPerPage, 1)
          setPage(1)
        }, 500)
      )
    },
    [timerId]
  )
  const getCoupons = (searchText: string, rowsPerPage: number, page: number) => {
    OrderApi.get(
      searchText,
      rowsPerPage,
      page,
      changeState.value,
      (res: any) => {
        setTotal(res.pages.total)

        setRows(res.data)
      },
      setIsLoading,
      filter
    )
  }
  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (newPrePage: number) => {
    setRowsPerPage(newPrePage)
    setPage(1)
  }
  useEffect(() => {
    if (search && page === 1) {
    } else {
      getCoupons(search, rowsPerPage, page)
    }
  }, [rowsPerPage, page, filter])
  useEffect(() => {
    if (added) {
      setAdded(false)
    }
  }, [rows])
  const {t} = useTranslation()
  const [changeState, setChangeState] = useState({value: 'allOrder'})

  // 'pending','preparing','in_progress','refunded','completed','canceled'
  const options = [
    {
      value: 'pending',
      label: t('pending'),
      icon: <PendingIcon color={changeState.value === 'pending' && '#fff'} />,
      color: '#f59d03',
    },
    {
      value: 'preparing',
      label: t('preparing'),
      icon: <Shipped color={changeState.value === 'preparing' && '#fff'} />,
      color: '#7239EA',
    },
    {
      value: 'in_progress',
      label: t('inProgress'),
      icon: <InProgress color={changeState.value === 'in_progress' && '#fff'} />,
      color: '#16ABFF',
    },
    {
      value: 'refunded',
      label: t('refunded'),
      icon: <Refund color={changeState.value === 'refunded' && '#fff'} />,
      color: '#004085',
    },
    {
      value: 'completed',
      label: t('completed'),
      icon: <Commpleted color={changeState.value === 'completed' && '#fff'} />,
      color: '#50CD89',
    },
    {
      value: 'canceled',
      label: t('canceled'),
      icon: <Canceled color={changeState.value === 'canceled' && '#fff'} />,
      color: '#EF305E',
    },
  ]
  const handelFilterData = async (state: any) => {
    OrderApi.get(
      '',
      rowsPerPage,
      page,
      state,
      (res: any) => {
        setTotal(res.pages.total)

        setRows(res.data)
      },
      setIsLoading
    )
  }

  return (
    <Stack mt='24px'>
      <Stack sx={{overflow: 'auto'}} width={'100%'}>
        <Stack pb='15px' width={'1082px'} gap='20px' direction={'row'}>
          {options.map((e, i) => (
            <Stack
              p='16px'
              borderRadius={'8px'}
              width={'164px'}
              height={'77px'}
              bgcolor={e.value === changeState.value ? '#405189' : '#fff'}
              gap={'8px'}
              border={e.value !== changeState.value ? '1px solid #a8a9ad3d' : 'none'}
              sx={{cursor: 'pointer', color: e.value === changeState.value ? 'red' : 'green'}}
              key={i}
              onClick={() => {
                if (e.value === changeState.value) {
                  handelFilterData('allOrder')
                  setChangeState({value: 'allOrder'})
                } else {
                  handelFilterData(e.value)
                  setChangeState({value: e.value})
                }
              }}
            >
              {e.icon}
              <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
                <Label
                  fs='14px'
                  color={e.value === changeState.value ? '#fff' : '#A8A9AD'}
                  text={e.label}
                />
                <svg
                  width='8'
                  height='8'
                  viewBox='0 0 8 8'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    cx='4'
                    cy='4'
                    r='4'
                    fill={e.value === changeState.value ? '#fff' : e.color}
                  />
                </svg>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>

      <div style={{marginTop: '15px'}} className={`card fds sha`}>
        <div className='card-body py-3 pb-5'>
          <Stack m='15px 0' width={'100%'} justifyContent={'space-between'} direction={'row'}>
            <Stack width={'200px'}>
              <Search search={search} handleSearch={handleSearch} />
            </Stack>
          </Stack>{' '}
          <div className='table-responsive overflow-auto  '>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <TableHead isActive={changeState.value} />
              {!added ? (
                <>
                  {' '}
                  {!isLoading ? (
                    <tbody className='mb-4'>
                      {rows.length === 0 ? (
                        <NoDataTable />
                      ) : (
                        rows.map((e: any, i: any) => (
                          <Row isActive={changeState.value} item={e} i={i} key={i} />
                        ))
                      )}
                    </tbody>
                  ) : (
                    <CircularProgress />
                  )}
                </>
              ) : (
                <h1>loading</h1>
              )}
            </table>{' '}
          </div>
          <PaginationSection
            rowsPerPageOptions={[5, 20, 30, 40, 50, 60]}
            count={total || 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* pagnation  item   */}
        </div>
      </div>
    </Stack>
  )
}
