import {Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import MainBtn from '../customTable/MainBtn'

export default function Tabs({
  active,
  setActive,
}: {
  active: number
  setActive: React.Dispatch<React.SetStateAction<number>>
}) {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  const tabs = [
    {name: t('Fashion & Jewelry'), isRead: true},
    {name: t('Fashion & Jewelry'), isRead: true},
    {name: t('Fashion & Jewelry'), isRead: true},
    {name: t('Fashion & Jewelry'), isRead: true},
    {name: t('Fashion & Jewelry'), isRead: true},
    {name: t('Fashion & Jewelry'), isRead: true},
    {name: t('Fashion & Jewelry'), isRead: true},
  ]
  return (
    <Stack sx={{overflowX: 'auto'}} p={'60px 0 20px 0'} gap='40px' direction={'row'}>
      {tabs.map((e, i) => (
        <Stack width={'max-content'}>
          {e.isRead && (
            <MainBtn
              without={i === active ? false : true}
              handelSubmit={() => setActive(i)}
              width={'max-content'}
              text={e.name}
            />
          )}
        </Stack>
      ))}
      <Stack width={'20px'}></Stack>
    </Stack>
  )
}
