import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import InputType from '../customTable/InputType'
import {useTranslation} from 'react-i18next'
import Vendor from '../../Api/vendor'
import * as Yup from 'yup'
import {ForgetSchema} from '../../yup/vendorSchema'
import InputCode from './InputCode'
import MainBtn from '../customTable/MainBtn'
import ValidationFun from '../../yup/ValidationFun'
import ErrorMsg from '../customTable/NewMsgErr'
import PhoneInput from '../customTable/PhoneInput'

export default function ForgetPassword({setIsActive, setCode, setPhone, value, setValue}: any) {
  const [userInfo, setUserInfo] = useState<any>({mobile: ''})

  const {t} = useTranslation()
  const [msgErr, setMsgErr] = React.useState<string>('')
  const [isLoading, setUsLoading] = React.useState(false)
  const [codeValue, setCodeValue] = useState<any>({})
  const [myCode, setMyCode] = useState('')
  const [errors, setErrors] = useState<any>({})

  const handilForgetPassword = async (e: any) => {
    e.preventDefault()
    ValidationFun({...userInfo, phone_code: value}, ForgetSchema, setErrors, (value: any) => {
      Vendor.forgetPassword(
        {...userInfo, phone_code: value},
        (res: any) => {
          setMyCode(res.code)

          setCode(res.code)

          setPhone(userInfo.mobile)
          setMsgErr('')

          // dispatch(actions.login(user.data.data.token))
          setUsLoading(false)
          set_is_true(false)
        },
        setUsLoading
      )
    })
  }
  const [is_true, set_is_true] = useState(true)
  const [isfocus, setFoucus] = useState('a')
  const sendCode = async (e: any) => {
    e.preventDefault()

    let isCode = ''
    for (const key in codeValue) {
      isCode += codeValue[key]
    }

    Vendor.checkCode(
      {...userInfo, phone_code: value, code: isCode},
      (res: any) => {
        setIsActive(2)
        setMsgErr('')
      },
      setUsLoading
    )
  }
  const arrCode = ['a', 'b', 'c', 'd']
  return (
    <>
      {is_true ? (
        <form onSubmit={handilForgetPassword} style={{width: '100%'}} action=''>
          <Stack gap='20px'>
            <Typography sx={{fontSize: '24px', fontWeight: '600', textAlign: 'center'}}>
              {t('forgotPassword')}
            </Typography>
            <PhoneInput
              errors={errors}
              value={value}
              data={userInfo}
              setData={setUserInfo}
              name={'mobile'}
              setValue={setValue}
            />

            <Typography sx={{fontSize: '13px', color: '#3E97FF', fontWeight: '600'}}>
              <span style={{color: '#7E8299'}}>{t('back')} </span>{' '}
              <span
                onClick={() => {
                  setIsActive(0)
                }}
                style={{color: '#3E97FF', cursor: 'pointer'}}
              >
                {t('login')}
              </span>
            </Typography>
            <MainBtn
              br='8px'
              bgcolor='#3C3AF7'
              isLoading={isLoading}
              text={'submit'}
              handelSubmit={handilForgetPassword}
              disabled={isLoading}
            />
          </Stack>{' '}
        </form>
      ) : (
        <form onSubmit={sendCode} style={{width: '100%'}} action=''>
          <Stack gap='20px'>
            <Typography sx={{fontSize: '24px', fontWeight: '600', textAlign: 'center'}}>
              {t('resetPassword')}
            </Typography>
            <Stack>
              <Typography
                sx={{fontSize: '18px', fontWeight: '500', textAlign: 'center', color: '#A1A5B7'}}
              >
                {t('enterCode')}
              </Typography>
              <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'center'}}>
                {'*'.repeat(userInfo.mobile.length - 3) +
                  userInfo.mobile.substr(userInfo.mobile.length - 3)}
              </Typography>
            </Stack>
            <Stack gap='20px' justifyContent={'center'} direction={'row'}>
              {['a', 'b', 'c', 'd'].map((e, i) => (
                <InputCode
                  isfocus={isfocus}
                  codeValue={codeValue}
                  e={e}
                  setCodeValue={setCodeValue}
                  setFoucus={setFoucus}
                  arrCode={arrCode}
                />
              ))}
            </Stack>
            <MainBtn
              br='8px'
              bgcolor='#3C3AF7'
              text={'submit'}
              isLoading={isLoading}
              handelSubmit={sendCode}
              disabled={isLoading}
            />
          </Stack>{' '}
        </form>
      )}
    </>
  )
}
