import {IconButton, Stack} from '@mui/material'
import React, {useState} from 'react'
import MainBtn from './MainBtn'
import CloseIcon from '@mui/icons-material/Close'
import PopUp from './PopUp'

export default function Filter({children, setFilter, filter, open, setOpen, setFilterData}: any) {
  const hasKeys = Object.keys(filter).length > 0

  return (
    <Stack>
      <Stack>
        <Stack position={'relative'}>
          <MainBtn
            handelSubmit={() => {
              setOpen(true)
            }}
            without
            text='filter'
          />
          {hasKeys && (
            <Stack
              borderRadius={'100%'}
              // p='2px'
              border='1px solid #f59d03;
'
              bgcolor={'#f59d03'}
              top='-15px'
              right={'-11px'}
              position={'absolute'}
            >
              <IconButton
                onClick={() => {
                  setFilter({})
                  setFilterData({})
                }}
                aria-label='delete'
                size='small'
              >
                <CloseIcon sx={{color: '#fff'}} fontSize='small' />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Stack>
      <PopUp width='xs' setOpen={setOpen} open={open}>
        {children}
      </PopUp>
    </Stack>
  )
}
