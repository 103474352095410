import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import MainBtn from '../customTable/MainBtn'
import PopUp from '../customTable/PopUp'
import ShowPlan from './ShowPlan'
import {useNavigate} from 'react-router-dom'
import Varefacation from './Varefacation'
import PackagesApi from '../../Api/Packages'

export default function Cards({data}: any) {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)
  const [isVaravacation, setIsVaravacation] = useState(false)
  const [signature, setSignature] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)

  return (
    <Stack mt='30px'>
      <Stack direction={{sm: 'row', xs: 'column'}} gap={'24px'}>
        <Stack
          sx={{
            background: 'var(--bs-app-footer-bg-color)',
            width: {xs: '100%'},
            borderRadius: '8px',
            padding: '24px',
            gap: '24px',
          }}
        >
          <Typography sx={{fontSize: '16px', fontWeight: '600'}}>{t('accountDetails')}</Typography>
          <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
            {t('sellerName')} : <span style={{color: '#787887'}}>{data.vendor_name}</span>
          </Typography>
          <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
            {t('mobileNumber')} : <span style={{color: '#787887'}}>{data.vendor_mobile}</span>
          </Typography>
          <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
            {t('sellerEmail')} : <span style={{color: '#787887'}}>{data.vendor_email}</span>
          </Typography>
          <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
            {t('country')} : <span style={{color: '#787887'}}>{data.country_name}</span>
          </Typography>
          <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
            {t('city')} : <span style={{color: '#787887'}}>{data.city_name}</span>
          </Typography>
        </Stack>
      </Stack>
      <Stack mt='24px'>
        <Stack
          sx={{
            background: 'var(--bs-app-footer-bg-color)',
            width: {xs: '100%', sm: '100%'},
            borderRadius: '8px',
            padding: '24px',
            gap: '24px',
          }}
        >
          <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
            {t('subscriptionsDetails')}
          </Typography>
          <Stack
            flexWrap={'wrap'}
            justifyContent={'space-between'}
            direction={{sm: 'row', xs: 'column'}}
            gap={'24px'}
          >
            <Stack gap='24px'>
              <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
                {t('planName')} : <span style={{color: '#009EF7'}}>{data.plan_name} </span>
              </Typography>{' '}
              <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
                {t('subscriptionStatus')} :{' '}
                <span style={{color: '#787887'}}>{data.status_text} </span>
              </Typography>{' '}
              <MainBtn
                secondary
                width='max-content'
                text='planFeatures'
                handelSubmit={() => {
                  setOpen(true)
                }}
              />
              <PopUp setOpen={setOpen} open={open} width={'md'}>
                <ShowPlan data={data.features} />
              </PopUp>
            </Stack>

            <Stack minWidth={'350px'} gap='24px'>
              <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
                {t('subscriptionType')} : <span style={{color: '#787887'}}>{data.type}</span>
              </Typography>{' '}
              <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
                {t('startDate')} : <span style={{color: '#787887'}}>{data.start_at} </span>
              </Typography>{' '}
              <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
                {t('endDate')} : <span style={{color: '#787887'}}>{data.expire_at}</span>
              </Typography>{' '}
            </Stack>
          </Stack>
          <Stack
            gap='20px'
            direction={'row'}
            flexWrap={'wrap'}
            justifyContent={'flex-end'}
            alignItems={'flex-end'}
          >
            <MainBtn
              handelSubmit={() => {
                navigate('/subscriptions-change-package')
              }}
              width='auto'
              text='upgradeSubscriptionPackage'
            />{' '}
            {data.type !== 'free' && (
              <MainBtn
                without
                handelSubmit={() => {
                  PackagesApi.renew((res: any) => {
                    setSignature(res.data.signature)
                    setIsVaravacation(true)
                  }, setIsLoading)
                }}
                width='auto'
                text='renew'
                isLoading={isLoading}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      <PopUp isLoading={isLoadingBtn} width='xs' open={isVaravacation}>
        {isVaravacation && (
          <Varefacation
            setIsLoading={setIsLoadingBtn}
            isLoading={isLoadingBtn}
            signature={signature}
            isRenew
            price={data && data.payment_details && data.payment_details.total}
            name={data.plan_name}
            setOpen={setIsVaravacation}
          />
        )}
      </PopUp>
    </Stack>
  )
}
