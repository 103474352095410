import React from 'react'
import {useTranslation} from 'react-i18next'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'

export default function TableHead({switchValue}: {switchValue: string}) {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)
  const headTable =
    switchValue !== 'cash' || (store.permissions && store.permissions.includes('services_fees_pay'))
      ? [
          {
            name: t('invoicesID'),
            class: 'min-w-200px',
          },
          {
            name: t('date'),
            class: 'min-w-150px',
          },
          {
            name: t('paidAmount'),
            class: 'min-w-150px',
          },
          {
            name: t('total'),
            class: 'min-w-150px',
          },
          {
            name: t('dueDate'),
            class: 'min-w-150px ',
          },
        ]
      : [
          {
            name: t('invoicesID'),
            class: 'min-w-200px',
          },
          {
            name: t('date'),
            class: 'min-w-150px',
          },
          {
            name: t('servesFees'),
            class: 'min-w-150px',
          },
          {
            name: t('total'),
            class: 'min-w-150px',
          },
          {
            name: t('dueDate'),
            class: 'min-w-150px ',
          },

          {
            name: t('actions'),
            class: 'max-w-520px',
          },
        ]

  return (
    <thead>
      <tr
        // style={{display:'flex',justifyContent:'space-between'}}
        className='fw-bold text-muted'
      >
        {headTable.map((e, i) => (
          <th
            style={{
              textAlign: i === 0 ? 'start' : 'start',
              width: i === headTable.length - 1 || i === 0 ? '80px' : 'auto',
            }}
            key={i}
            className={e.class}
          >
            {e.name}
          </th>
        ))}
      </tr>
    </thead>
  )
}
