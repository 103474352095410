import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import TitlePage from '../../customTable/TitlePage'
import {Stack} from '@mui/material'
import InputsText from './InputsText'
import RestSaveButton from '../../customTable/RestSaveBtns'
import {useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {MenuSchema, MenuSchemaWithLink} from '../../../yup/menu'
import * as Yup from 'yup'
import OptionsPage from './OptionsPage'
import MenuApi from '../../../Api/menu'
import ErrorMsg from '../../customTable/ErrorMsg'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import ValidationFun from '../../../yup/ValidationFun'

export default function FormMenu() {
  const {t} = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<any>({
    parent_id: 0,
    position: 0,
    url_link: {},
    isLink: 1,
    page_id: '-1',
  })
  const {id} = useParams()

  const bannerInfo = {
    title: id ? t('editMenu') : t('createMenu'),
    home: t('home'),
    linkHome: '/',
  }
  const [msgErr, setMsgErr] = React.useState<string>(' ')
  const navigate = useNavigate()
  const [isEdit, setIsEdit] = useState(false)
  const [isLoadingItem, setIsLoadingItem] = useState(false)
  const getMenu = () => {
    setIsEdit(true)
    MenuApi.getOne(
      id,
      (res: any) => {
        setData({
          ...res.data,
          page_id: res.data.page_id ? res.data.page_id : '-1',
          url_link: !res.data.page_id ? res.data.url_link : {},
        })
        setIsEdit(false)
      },
      setIsLoadingItem
    )
  }
  useEffect(() => {
    if (id) {
      getMenu()
    }
  }, [id])
  const [languagess, setlanguagess] = useState(['en'])
  const store = useSelector((state: RootState) => state.vender)

  useEffect(() => {
    setlanguagess(store.storeLangs)
  }, [store.storeLangs])
  const [errors, setErrors] = useState<any>({})

  const handelSubmit = async () => {
    let newData = data
    if (data.page_id === '-1') {
      ValidationFun(
        {...data, sort: data.sort ? data.sort : 0},
        MenuSchema(languagess),
        setErrors,
        (res: any) => {
          if (id) {
            MenuApi.put(
              {
                ...data,
                page_id: null,
                parent_id: data.parent_id !== 0 ? data.parent_id : null,
                sort: data.sort ? data.sort : 0,
              },
              id,
              () => {},
              setIsLoading
            )
          } else {
            MenuApi.add(
              {
                ...data,
                page_id: null,
                parent_id: data.parent_id !== 0 ? data.parent_id : null,
                sort: data.sort ? data.sort : 0,
              },
              () => {
                setData({
                  parent_id: 0,
                  position: 0,
                  url_link: {},
                  isLink: 1,
                  page_id: '-1',
                })
                navigate('/storeManagement/menu')
                setMsgErr('')
              },
              setIsLoading
            )
          }
        }
      )
    } else if (data.page_id !== '-1') {
      ValidationFun(
        {...data, sort: data.sort ? data.sort : 0},
        MenuSchemaWithLink(languagess),
        setErrors,
        (res: any) => {
          if (id) {
            MenuApi.put(
              {
                ...data,
                url_link: null,
                parent_id: data.parent_id !== 0 ? data.parent_id : null,
                sort: data.sort ? data.sort : 0,
              },
              id,
              () => {},
              setIsLoading
            )
          } else {
            MenuApi.add(
              {
                ...data,
                url_link: null,
                parent_id: data.parent_id !== 0 ? data.parent_id : null,
                sort: data.sort ? data.sort : 0,
              },
              () => {
                setData({
                  parent_id: 0,
                  position: 0,
                  url_link: {},
                  isLink: 1,
                  page_id: '-1',
                })
                navigate('/storeManagement/menu')
              },
              setIsLoading
            )
          }
        }
      )
      // MenuSchemaWithLink(languagess)
      //   .validate(data)
      //   .then((res: any) => {
      //     // setIsLoadingBtn(true)
      //     if (id) {
      //       MenuApi.put(
      //         {...data, url_link: null, parent_id: data.parent_id !== 0 ? data.parent_id : null},
      //         id,
      //         () => {},
      //         setIsLoading
      //       )
      //     } else {
      //       MenuApi.add(
      //         {
      //           ...data,
      //           url_link: null,
      //           parent_id: data.parent_id !== 0 ? data.parent_id : null,
      //         },
      //         () => {
      //           setData({
      //             parent_id: 0,
      //             position: 0,
      //             url_link: {},
      //             isLink: 1,
      //             page_id: '-1',
      //           })
      //           navigate('/storeManagement/menu')
      //         },
      //         setIsLoading
      //       )
      //     }
      //     setMsgErr('')
      //     // setIsLoadingBtn(false)
      //   })
      //   .catch((error: Yup.ValidationError) => {
      //     setIsLoading(false)

      //     setMsgErr(error.message)
      //     // setIsLoadingBtn(false)
      //   })
    }
  }
  return (
    <Stack>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <TitlePage info={bannerInfo} />
      </Stack>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <Stack
          sx={{marginTop: '30px'}}
          width={'100%'}
          gap={'15px'}
          direction={{xs: 'column', sm: 'row'}}
        >
          <Stack
            width={{xs: '100%', sm: '100%'}}
            // height={'700px'}
            bgcolor={'var(--bs-app-footer-bg-color)'}
            borderRadius={'8px'}
            p='24px'
          >
            <InputsText
              errors={errors}
              isEdit={isEdit}
              msgErr={msgErr}
              setMsgErr={setMsgErr}
              data={data}
              setData={setData}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack mt='60px' width={'100%'}>
        <RestSaveButton
          errors={errors}
          isLoading={isLoading}
          SaveText={t('saveChange')}
          cancelText={t('cancel')}
          submit={handelSubmit}
          cancel={() => {
            navigate('/storeManagement/menu')
          }}
        />
      </Stack>
      <Stack alignItems={'flex-end'}>
        <ErrorMsg msgErr={msgErr} name='' />
      </Stack>
    </Stack>
  )
}
