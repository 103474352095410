import React, {useState, useEffect} from 'react'
import RedStart from '../../../../customTable/RedStart'
import {Box, Container, Stack, Typography} from '@mui/material'
import InputType from '../../../../customTable/InputType'
import SaveButton from '../../../../customTable/SaveButton'
import MySelect from '../../../../customTable/CustomSelectTest'
import SelectByLang from '../../../../customTable/SelectByLang'
import Tabs from './Tabs'
import UploadImg from '../../../../customTable/UploadImg'
import * as Yup from 'yup'
import {OptionValueschema} from '../../../../../yup/OptionValue'
import Product from '../../../../../Api/product'
import Loading from '../../../../customTable/LoadingForm'
import {useTranslation} from 'react-i18next'
import TabsLangWithFlag from '../../../../customTable/LangTabs'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../store'
import SetLang from '../../../../../utils/SetLangs'
import ValidationFun from '../../../../../yup/ValidationFun'

export default function OptionForm({
  type,
  values,
  setOpen,
  optionValue,
  setOptionValue,
  open,
  option_id,
  isSelected,
  setIsSelected,
  options,
  setValues,
  isLoadingOptionValue,
}: any) {
  const {t} = useTranslation()
  const currency = localStorage.getItem('currency')

  const [selectData, setSelectData] = React.useState(null)
  const [count, setCount] = useState(0)
  const store = useSelector((state: RootState) => state.vender)

  const [languages, setlanguages] = useState(['en'])
  const [errors, setErrors] = useState<any>({})
  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])
  const handelSave = () => {
    ValidationFun(
      {
        ...option,
        price_action: option.price_action === 1 ? '+' : '-',
        option_image: base64Image,
        isImage: type === 'text' ? 0 : values.is_upload_image,
        option_value_id: option.option_value_id === '' ? 0 : option.option_value_id,
        languages: languages,
      },
      OptionValueschema(languages),
      setErrors,
      () => {
        if (
          count === 0 &&
          (!values.product_option_values ||
            (values.product_option_values && values.product_option_values.length === 0))
        ) {
          setValues({...values, isDefult: 0})
        }

        setUsLoading(true)

        setOptionValue([
          ...optionValue,
          {
            ...option,
            price_action: option.price_action === 1 ? '+' : '-',
            option_image: base64Image,
            idDate: count,
            option_value_local: selectData,
            is_default: 0,
          },
        ])

        setCount(count + 1)
        setUsLoading(false)
        setIsSelected([...isSelected, option.option_value_id])
        setBase64Image('')

        setOption({
          price_action: 1,
          option_value_id: '',
          price_value: '0',
        })
        setOpen(false)
      }
    )
    // OptionValueschema(languages)
    //   .validate({
    //     ...option,
    //     price_action: option.price_action === 1 ? '+' : '-',
    //     option_image: base64Image,
    //     isImage: type === 'text' ? 0 : values.is_upload_image,
    //     option_value_id: option.option_value_id === '' ? 0 : option.option_value_id,
    //     languages: languages,
    //   })
    //   .then(async () => {
    //     try {
    //       if (
    //         count === 0 &&
    //         (!values.product_option_values ||
    //           (values.product_option_values && values.product_option_values.length === 0))
    //       ) {
    //         setValues({...values, isDefult: 0})
    //       }

    //       setUsLoading(true)
    //       setOptionValue([
    //         ...optionValue,
    //         {
    //           ...option,
    //           price_action: option.price_action === 1 ? '+' : '-',
    //           option_image: base64Image,
    //           idDate: count,
    //           option_value_local: selectData,
    //           is_default: 1,
    //         },
    //       ])

    //       setCount(count + 1)
    //       setUsLoading(false)
    //       setIsSelected([...isSelected, option.option_value_id])
    //       setBase64Image('')

    //       setOption({
    //         price_action: 1,
    //         option_value_id: '',
    //         price_value: '0',
    //       })
    //       setOpen(false)
    //     } catch (error: any) {
    //       setMsgErr(error.response.data.message)
    //       setUsLoading(false)
    //     }
    //   })
    //   .catch((error: Yup.ValidationError) => {
    //     setMsgErr(error.message)
    //     setUsLoading(false)
    //   })
  }

  const [isLoading, setUsLoading] = React.useState(false)

  const [base64Image, setBase64Image] = React.useState('')
  const [option, setOption] = React.useState<any>({
    price_action: 1,
    option_value_id: '',
    price_value: '0',
    sort: 0,
  })
  const [selectedLang, setSelectedLang] = useState<any>([])
  const [defLang, setDefLang] = useState('')

  const getLangs = async () => {
    setSelectedLang(store.langs)
    setDefLang(
      store.langs.some((e: any) => e.code === store.defLang) ? store.defLang : store.langs[0].code
    )
  }
  useEffect(() => {
    getLangs()
  }, [store.langs])
  return (
    <Container
      lang={store.lang}
      dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
      sx={{padding: '31px 24px', position: 'relative'}}
      maxWidth='md'
    >
      <p style={{width: '500px'}}></p>

      <Stack width='100%'>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '19px',
            color: 'var(--bs-body-color)',
            lineHeight: '24px',
          }}
        >
          {t('addOption')}
        </Typography>
        <Stack gap='30px' mt='32px'>
          {type !== 'text' && values.is_upload_image !== 0 && (
            <>
              {open && (
                <Stack gap={'12px'}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      color: 'var(--bs-body-color)',
                      lineHeight: '16px',
                    }}
                  >
                    {t('image')}
                  </Typography>
                  <Stack
                    width='120px'
                    height={'120px'}
                    sx={{
                      boxShadow: '0px 6.5px 19.5px 6.5px rgba(0, 0, 0, 0.075)',
                      borderRadius: '7px',
                      marginTop: '10px',
                    }}
                  >
                    <UploadImg
                      name='option_image'
                      errors={errors}
                      ase64Image={base64Image}
                      setBase64Image={setBase64Image}
                    />
                  </Stack>
                </Stack>
              )}
            </>
          )}

          {type !== 'text' && values.is_upload_image !== 0 && (
            <Stack position={'relative'} gap={'12px'}>
              <Stack direction={'row'} gap='5px'>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: 'var(--bs-body-color)',
                    lineHeight: '16px',
                  }}
                >
                  {' '}
                  {t('optionName')}
                </Typography>
                <RedStart />
              </Stack>
              <Box width='100%'>
                {selectedLang.map((e: any, i: number) => (
                  <>
                    {e.code === defLang && (
                      <>
                        <Stack key={i} sx={{width: {xs: '100%'}}} gap='10px'>
                          <Box sx={{width: {sm: '100%', xs: '100%'}}}>
                            <InputType
                              errors={errors}
                              child={e.code}
                              name={'option_value'}
                              change={setOption}
                              value={option}
                              type='text'
                            />
                          </Box>
                        </Stack>
                      </>
                    )}
                  </>
                ))}
              </Box>

              <Stack
                position={'absolute'}
                top={'25px'}
                left={store.isRtl === 1 ? '0' : 'auto'}
                right={store.isRtl === 0 ? '0' : 'auto'}
              >
                <TabsLangWithFlag
                  errors={errors}
                  name={'option_value'}
                  activeLang={defLang}
                  setActiveLang={setDefLang}
                  languages={selectedLang}
                />
                {/* <Tabs isActive={isActive} setIsActive={setIsActive} /> */}
              </Stack>
            </Stack>
          )}
          {(type === 'text' || values.is_upload_image === 0) && open && (
            <>
              <SelectByLang
                isLoading={isLoadingOptionValue}
                value={''}
                errors={errors}
                setSelectData={setSelectData}
                values={option}
                name={'option_value_id'}
                option={
                  isSelected.length > 0
                    ? options.filter((item: any) => !isSelected.includes(item.id))
                    : options
                }
              />
            </>
          )}
          <Stack gap={'12px'}>
            <Stack direction={'row'} gap='5px'>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '16px',
                  color: 'var(--bs-body-color)',
                  lineHeight: '16px',
                }}
              >
                {t('priceOpertion')}
              </Typography>
              <RedStart />
            </Stack>
            <Box width='100%'>
              <Stack
                className='select-with-input'
                direction={'row'}
                gap={'10px'}
                sx={{
                  width: {xs: '100%'},
                  background: 'var(--bs-body-bg)',
                  border: '1px solid var(--bs-gray-300)',
                }}
              >
                <Box sx={{width: {sm: '50%', xs: '50%'}}}>
                  <MySelect
                    option={[
                      {name: '-', id: 2},

                      {name: '+', id: 1},
                    ]}
                    values={option}
                    setValues={setOption}
                    name='price_action'
                    value={{id: 1}}
                    withoutBorder={true}
                  />
                  {/* <CustomSelect  name='discount_type' /> */}
                </Box>
                <Box sx={{width: '1px', height: '43px', background: 'var(--bs-gray-300)'}}></Box>
                <Box position={'relative'} sx={{width: {sm: '50%', xs: '50%'}}}>
                  <InputType
                    errors={errors}
                    name={'price_value'}
                    withoutBorder={true}
                    change={setOption}
                    value={option}
                    type='number'
                  />
                  <Stack
                    sx={{
                      background: 'var(--bs-text-muted)',
                      top: '0',
                      right: store.isRtl === 0 ? 0 : 'auto',
                      left: store.isRtl !== 0 ? 0 : 'auto',
                      height: '100%',
                      width: '60px',
                      borderRadius: '3px',
                    }}
                    alignItems={'center'}
                    justifyContent={'center'}
                    position={'absolute'}
                  >
                    <Typography sx={{color: 'white', fontSize: '16px'}}>{currency}</Typography>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Stack>

          <Stack gap={'12px'}>
            <Stack direction={'row'} gap='5px'>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '16px',
                  color: 'var(--bs-body-color)',
                  lineHeight: '16px',
                }}
              >
                {t('sort')}
              </Typography>
              {/* <RedStart /> */}
            </Stack>
            <Box width='100%'>
              <Box sx={{width: '100%'}}>
                <InputType
                  name={'sort'}
                  errors={errors}
                  change={setOption}
                  value={option}
                  type='number'
                />
              </Box>
            </Box>
          </Stack>
        </Stack>
        <SaveButton text={t('save')} submit={handelSave} isLoading={false} setOpen={setOpen} />
      </Stack>
      <Stack alignItems={'flex-end'}></Stack>
      {isLoading && <Loading />}
    </Container>
  )
}
