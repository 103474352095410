import {Radio, Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import BasicSpeedDial from './Actions'
import EditOption from './EditOption'
import Product from '../../../../../Api/product'
import {useParams} from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, {AlertProps} from '@mui/material/Alert'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../store'
import DeletedMsg from '../../../../customTable/DeleteMsg'
import SimpleLoading from '../../../../customTable/SimpleLoading'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
export default function Row({
  item,
  optionValue,
  setOptionValue,
  type,
  values,
  option_id,
  count,
  setCount,
  isSelected,
  setIsSelected,
  options,
  setIsGetOption,
  setLoading,
  getSelectedOptions,
  setValues,
  isLoadingOptionValue,
}: any) {
  const [open, setOpen] = React.useState(false)
  const [isDelete, setIsDelete] = useState(true)
  const [editItem, setEditItem] = useState(item)
  const {id} = useParams()
  const [openTost, setOpenTost] = React.useState(false)
  const store = useSelector((state: RootState) => state.vender)
  const [openDelete, setOpenDelete] = useState(false)
  const [isloadingDelete, setIsLoadingDelete] = useState(false)
  const [isLoadingDefult, setIsLoadingDefult] = useState(false)
  const handelDelete = () => {
    if (
      values.is_require === 1 &&
      (values.isDefult === editItem.id ||
        ((values.isDefult || values.isDefult === 0) && values.isDefult === editItem.idDate))
    ) {
      setOpenTost(true)
    } else if (item.id) {
      if (count === 1) {
        setOpenTost(true)
      } else {
        setCount(count - 1)
        setLoading(true)

        Product.destroyOptionProduct(
          item.id,
          id,
          values.id,
          () => {
            setIsSelected(isSelected.filter((e: number) => e !== item.option_detail_id))
            setValues({
              ...values,
              product_option_values: values.product_option_values.filter(
                (e: any) => e.id !== item.id
              ),
            })
            setLoading(!true)

            // setIsDelete(false)
          },
          setIsLoadingDelete
        )
      }
    } else {
      if (isSelected.length <= 2) {
        setOpenTost(true)
      } else {
        setLoading(true)

        setIsSelected(isSelected.filter((e: number) => e !== item.option_value_id))

        setOptionValue(optionValue.filter((e: any) => e.idDate !== item.idDate))
        setTimeout(() => {
          setLoading(false)
        }, 100)
      }
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenTost(false)
  }

  return (
    <>
      {isDelete && (
        <Stack
          sx={{
            borderTop: '1px solid var(--bs-gray-300)',
            borderBottom: '1px solid var(--bs-gray-300)',
          }}
          direction={'row'}
          width='100%'
        >
          {values.is_require === 1 && (
            <Stack
              width={'20%'}
              sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
            >
              {/* {editItem.idDate} */}
              {isLoadingDefult ? (
                <SimpleLoading />
              ) : (
                <Typography
                  onClick={() => {
                    if (editItem.id) {
                      Product.editOptionProduct(
                        editItem.id,
                        id,
                        values.id,
                        {...editItem, option_value_id: editItem.option_detail_id, is_defult: 1},
                        () => {
                          setValues({...values, isDefult: editItem.id})
                        },
                        setIsLoadingDefult
                      )
                    } else {
                      setValues({...values, isDefult: editItem.id || editItem.idDate})
                    }
                  }}
                >
                  <Radio
                    checked={
                      (editItem.id && values.isDefult === editItem.id) ||
                      ((values.isDefult || values.isDefult === 0) &&
                        values.isDefult === editItem.idDate)
                    }
                  />
                </Typography>
              )}
            </Stack>
          )}

          <Stack
            justifyContent={'center'}
            width={'32%'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            <Typography onClick={() => {}}>
              {editItem.option_value_local && editItem.option_value_local.option_value
                ? editItem.option_value_local.option_value[store.lang] ||
                  editItem.option_value_local.option_value[store.defLang]
                : editItem.option_detail
                ? editItem.option_detail.option_value[store.lang] ||
                  editItem.option_detail.option_value[store.defLang]
                : editItem.option_value &&
                  (editItem.option_value[store.lang] || editItem.option_value[store.defLang])
                ? editItem.option_value[store.lang] || editItem.option_value[store.defLang]
                : 'New Option'}
            </Typography>
          </Stack>
          <Stack
            justifyContent={'center'}
            width={'40%'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            <Typography>{editItem.price_value} </Typography>
          </Stack>
          <Stack
            justifyContent={'center'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
            width={'24%'}
          >
            <Typography>{editItem.sort}</Typography>
          </Stack>
          <Stack width={'42px'}>
            {isloadingDelete ? (
              <SimpleLoading />
            ) : (
              <BasicSpeedDial
                setOpen={setOpen}
                setOptionValue={setOptionValue}
                optionValue={optionValue}
                item={editItem}
                valuesId={values.id}
                setIsDelete={setIsDelete}
                count={count}
                setCount={setCount}
                isSelected={isSelected}
                setIsSelected={setIsSelected}
                handelDelete={handelDelete}
                openTost={openTost}
                setOpenTost={setOpenTost}
                setOpenDelete={setOpenDelete}
              />
            )}
          </Stack>
          <EditOption
            setValues={setValues}
            isLoadingOptionValue={isLoadingOptionValue}
            type={type}
            option_id={option_id}
            values={values}
            item={editItem}
            open={open}
            setOpen={setOpen}
            optionValue={optionValue}
            setOptionValue={setOptionValue}
            setEditItem={setEditItem}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            options={options}
            setIsGetOption={setIsGetOption}
            getSelectedOptions={getSelectedOptions}
          />
          <DeletedMsg open={openDelete} setOpen={setOpenDelete} onClick={handelDelete} />
          <Snackbar open={openTost} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity='warning' sx={{width: '100%'}}>
              The last saved option cannot be deleted
            </Alert>
          </Snackbar>
          {/* <button onClick={handelDelete}>{item.option_value_id}</button> */}
        </Stack>
      )}
    </>
  )
}
