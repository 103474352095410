import React, {useEffect, useState} from 'react'
import HomeApi from '../../../Api/home'
import {Stack} from '@mui/system'
import {Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {useDispatch} from 'react-redux'
import {actions} from '../../../store/vender/Auth/auth.slice'
import JwtService from '../../../utils/TokenServices'
import {toast} from 'react-toastify'
import Loading from '../../../modules/customTable/LoadingForm'
import PupUpImage from '../../../modules/customTable/PupUpImage'
import FixedNumber from '../../../modules/customTable/FixedNumber'

export default function TopSilling() {
  const [products, setProducts] = useState<any>([])
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const getTopSilling = () => {
    HomeApi.getSillingTop((res: any) => {
      setProducts(res.data)
    }, setIsLoading)
  }

  useEffect(() => {
    getTopSilling()
  }, [])

  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack
      position={'relative'}
      sx={{
        background: 'var(--bs-app-footer-bg-color)',
        borderRadius: '8px',
        padding: '28px 28px 15px 28px',
        width: 'calc(35% - 10px)',
        minHeight: '431px',
        '@media (max-width: 1200px)': {
          width: '100%',
        },
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Typography sx={{fontSize: '18px', fontWeight: '600'}}>{t('topProduct')}</Typography>
          <Stack mt={'32px'}>
            <Stack justifyContent={'space-between'} direction={'row'}>
              <Typography sx={{fontSize: '12px', fontWeight: '600', color: '#A1A5B7'}}>
                {t('item')}
              </Typography>
              <Typography sx={{fontSize: '12px', fontWeight: '600', color: '#A1A5B7'}}>
                {t('totalPrice')}
              </Typography>
            </Stack>
            <Stack>
              {products.map((e: any, i: any) => (
                <Stack
                  key={i}
                  borderBottom={
                    i !== products.length - 1 ? '1px dashed var(--bs-gray-300)' : 'none'
                  }
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  direction={'row'}
                  p={'13px 0'}
                >
                  <Stack alignItems={'center'} gap='8px' direction={'row'}>
                    <PupUpImage image={e.image}>
                      <img
                        style={{width: '50px', height: '50px'}}
                        src={e.image && e.image}
                        alt=''
                      />
                    </PupUpImage>
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: '600',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          WebkitLineClamp: 2, // تحديد عدد الأسطر
                        }}
                      >
                        {e.name && e.name[store.lang]}
                      </Typography>
                      <Typography sx={{fontSize: '12px', fontWeight: '600', color: '#A1A5B7'}}>
                        {e.sku}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Typography sx={{fontSize: '12px', fontWeight: '600', color: '#A1A5B7'}}>
                    <FixedNumber num={e.total_earn} />
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  )
}
