import clsx from 'clsx'
import {FormikProps} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {fileListToBase64} from '../../../utils/image'
import {useDispatch} from 'react-redux'
import React, {useState} from 'react'
import {actions} from '../../../store/vender/Auth/auth.slice'
import ImgMsg from '../../customTable/ImgMsg'
import ErrorMsg from '../../customTable/NewMsgErr'
import SizeImage from '../../customTable/SizeImage'
import {useSelector} from 'react-redux'
type Props<T> = {
  formik: FormikProps<T | any>
  isShow?: boolean
  image: string[] | null | Blob | any
  error?: string | null
  touched?: boolean | null
  className?: string
  errors?: any
  name?: any
  child?: any
  hight?: string
  width?: string
}

export function IamgeSection<T>({
  formik,
  isShow = true,
  image,
  error,
  touched,
  className,
  errors,
  name,
  child,
  hight,
  width,
}: Props<T>) {
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (formik.values.image) {
      dispatch(actions.setVariationImg(formik.values.image))
    }
  }, [formik.values.image])
  const [isWidth, setIsWidth] = useState(false)
  const [isHieght, setIsHieght] = useState(false)
  const {sizeImages} = useSelector((state: any) => state.vender)
  return (
    <div className={clsx('card-body pt-0', className)}>
      <div
        className=' image-input-empty image-input-outline image-input-placeholder mb-3'
        data-kt-image-input='true'
      >
        <label
          htmlFor='image'
          className=' btn-active-color-primary  bg-body shadow'
          data-kt-image-input-action='change'
          data-bs-toggle='tooltip'
          title='Change image'
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: (width && isWidth) || (isHieght && hight) ? '1px solid red' : 'none',
              borderRadius: '8px',
            }}
            className='image-input-wrapper w-150px h-150px'
          >
            {formik.values.image ? (
              <img
                src={
                  typeof formik.values.image === 'object'
                    ? URL.createObjectURL((formik.values.image as any)[0])
                    : formik.values.image
                }
                alt='User Avatar'
                className='w-150px h-150px'
                style={{maxWidth: '100%', maxHeight: '100%'}}
              />
            ) : (
              <img
                src={toAbsoluteUrl('/media/avatars/img.svg')}
                alt='user-info'
                className='w-100px h-100px'
              />
            )}
          </div>
          <input
            id='image'
            type='file'
            multiple={false}
            style={{display: 'none'}}
            accept='.jpg, .jpeg, .png, .svg'
            onChange={(e) => {
              const fileList = e.target.files as FileList

              // تحقق من وجود الملفات
              if (!fileList || fileList.length === 0) {
                formik.setFieldError('image', 'No file selected')
                return
              }

              // التحقق من العدد
              if (fileList.length > 1) {
                formik.setFieldError('image', 'Please do not take more than 4 items')
                formik.setFieldTouched('image', true)
                e.target.value = '' // Clear the value of the input element
                return
              }

              const file = fileList[0]

              // التحقق من الحجم
              const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2)

              const reader = new FileReader()
              reader.readAsDataURL(file)

              reader.onload = () => {
                const base64 = reader.result as string

                // استخراج الأبعاد
                const img = new window.Image() as HTMLImageElement
                img.src = base64

                img.onload = () => {
                  if (width && sizeImages[width] !== img.width) {
                    setIsWidth(true)
                  }
                  if (hight && sizeImages[hight] !== img.height) {
                    setIsHieght(true)
                  }

                  console.log(`File Size: ${fileSizeInMB} MB`)

                  // تحديث القيمة في Formik
                  formik.setFieldValue('image', base64)
                }
              }

              reader.onerror = (error) => {
                console.error('Error reading file:', error)
                formik.setFieldError('image', 'Error reading file')
              }
            }}
            onFocus={(e) => {
              formik.setFieldTouched('image', true)
            }}
          />
        </label>
        {/*  check  if  the  formik  image  is  found  then  show  x  icon  */}
      </div>
      <SizeImage width={width} hight={hight} />

      {error && touched && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{error}</div>
        </div>
      )}
      {errors && !child && errors[name] && typeof errors[name] === 'string' ? (
        <ErrorMsg msgErr={errors[name]} />
      ) : errors &&
        child &&
        errors[`${name}.${child}`] &&
        typeof errors[`${name}.${child}`] === 'string' ? (
        <ErrorMsg child={child} msgErr={errors[`${name}.${child}`]} />
      ) : (
        ''
      )}
    </div>
  )
}
