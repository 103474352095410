import {Stack} from '@mui/material'
import React from 'react'
import PhoneInputWithCountrySelect from 'react-phone-number-input'
import ErrorMsg from './NewMsgErr'

export default function PhoneInput({setValue, value, data, setData, name, errors}: any) {
  return (
    <>
      <Stack
        direction={'row'}
        borderRadius={'6.18px'}
        border='1px solid var(--bs-gray-300)'
        width={'100%'}
        p={'0 13px'}
        bgcolor={'var(--bs-body-bg)'}
        height={'47px'}
        alignItems={'center'}
        sx={{background: 'var(--bs-body-bg)'}}
        dir='ltr'
      >
        <Stack
          height={'80%'}
          sx={{borderRight: '1px solid  var(--bs-gray-300)', background: 'var(--bs-body-bg)'}}
        >
          <PhoneInputWithCountrySelect
            style={{width: '110px', height: '38px'}}
            placeholder='Enter phone number'
            international
            defaultCountry='LB'
            addInternationalOption={false}
            value={value}
            disableCountryCode={true}
            readOnly={true}
            onChange={(phoneNumber: any) => {
              setValue(phoneNumber)
            }}
            countries={['LB', 'IQ', 'PS', 'IL']}
          />
        </Stack>
        <input
          style={{
            border: 'none',
            background: 'var(--bs-body-bg)',
            width: 'calc(100%)',
            padding: '12.0625px 13px 12.5px',
            color: 'var(--bs-text-dark)',
          }}
          className='login-input'
          placeholder='Number Phone'
          type='tel'
          value={data.mobile}
          onChange={(e: any) => setData({...data, [name]: e.target.value})}
        />
      </Stack>
      <ErrorMsg msgErr={errors['mobile']} />
    </>
  )
}
