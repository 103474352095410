import {Box, Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../customTable/Label'
import Image from '../customTable/Image'
import MainBtn from '../customTable/MainBtn'
import PopUp from '../customTable/PopUp'

export default function TemplateCard({current, i}: any) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Stack
        p='16px'
        gap='18px'
        width={{lg: '195px', sm: '40%', xs: '80%', md: 'calc(50% - 12px)'}}
        borderRadius={'8px'}
        bgcolor={'#F8F8F8'}
      >
        <Label fw='500' fs='16px' text='name' />
        <Box sx={{width: '100%', height: '148px'}}>
          <Image
            sx={{height: '100%', width: '100%', borderRadius: '8px'}}
            src={
              'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg'
            }
          />
        </Box>
        {i === current ? (
          <Stack justifyContent={'center'}>
            <MainBtn handelSubmit={() => {}} text='currentTemplate' without />
          </Stack>
        ) : (
          <Stack gap='16px' justifyContent={'center'} direction={'row'}>
            <MainBtn
              minWidth='0px'
              width='73px'
              handelSubmit={() => {
                setOpen(true)
              }}
              text='select'
            />
            <MainBtn minWidth='0px' width='73px' handelSubmit={() => {}} text='preview' without />
          </Stack>
        )}
        <PopUp width='sm' open={open} setOpen={setOpen}>
          <Stack
            alignItems={'center'}
            borderRadius={'8px'}
            bgcolor={'#fff'}
            p={{xs: '24px', md: '40px'}}
          >
            <Stack width={{md: '419px'}} alignItems={'center'} gap='16px'>
              <Label align='center' fs='20px' text='yourAreGoingChangeYourStoreTemplate' />
              <Stack gap='5px' direction={'row'}>
                <Label
                  align='center'
                  color='#B5B5C3'
                  fs='14px'
                  text='ChangingTheTemplateWillIncurAnAdditionalFeeOf'
                />
              </Stack>

              <Stack mt='24px' gap='24px' justifyContent={'center'} direction={'row'}>
                <MainBtn
                  width='fit-content'
                  handelSubmit={() => {
                    setOpen(true)
                  }}
                  text='confirm'
                />
                <MainBtn width='fit-content' handelSubmit={() => {}} text='cancel' without />
              </Stack>
            </Stack>
          </Stack>
        </PopUp>
      </Stack>
    </>
  )
}
