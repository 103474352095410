import {Box, Stack} from '@mui/material'
import React, {useState} from 'react'
import TitlePage from '../customTable/TitlePage'
import {useTranslation} from 'react-i18next'
import Label from '../customTable/Label'
import PupUpImage from '../customTable/PupUpImage'
import Image from '../customTable/Image'
import MainBtn from '../customTable/MainBtn'
import {useNavigate} from 'react-router-dom'
import Tabs from './Tabs'
import TemplateCard from './TemplateCard'

export default function TemplatesList() {
  const {t} = useTranslation()

  const bannerInfo = {
    title: t('templatesList'),
    home: t('home'),
    linkHome: '/',
  }
  const navigate = useNavigate()
  const [active, setActive] = useState(0)

  return (
    <Stack>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <TitlePage info={bannerInfo} />

        <Stack gap={'10px'} direction={'row'}></Stack>
      </Stack>
      <Stack
        gap='24px'
        minHeight={'55vh'}
        p={{xs: '32px', md: '56px 80px'}}
        style={{marginTop: '30px'}}
        className={`card fds sha`}
      >
        <Label align='center' fw='600' fs='36px' text='chooseTheTemplateThatMeetsYourNeeds' />
        <Tabs active={active} setActive={setActive} />

        <Stack
          justifyContent={'center'}
          gap={{xs: '24px', lg: '47px'}}
          direction={'row'}
          flexWrap={'wrap'}
        >
          {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((e: any, i: any) => (
            <TemplateCard i={i} current={0} />
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}
