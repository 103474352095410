import {Stack, Table, TableBody, TableHead, TableRow} from '@mui/material'
import React, {useEffect, useState} from 'react'
import LogoCloud from '../../../svg/LogoCloud'
import Label from '../../customTable/Label'
import Logo from '../../../svg/Logo'
import LabelWithValue from './LabelWithValue'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import {styled} from '@mui/material/styles'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import PackagesApi from '../../../Api/Packages'

const StyledTableCell = styled(TableCell)(({width}) => ({
  [`&.${tableCellClasses.head}`]: {
    width: width || 'auto',
    borderRight: width === '184px' ? '1px solid #E0E0E0' : 'none',
    borderLeft: width === '184px' ? '1px solid #E0E0E0' : 'none',
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    borderRight: width === '154px' ? '1px solid #E0E0E0' : 'none',
    borderLeft: width === '154px' ? '1px solid #E0E0E0' : 'none',
  },
}))

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {},
}))

function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
  return {name, calories, fat, carbs, protein}
}

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Eclair', 262, 16.0, 24, 6.0),
// ]
export default function NewInvoice({id}: any) {
  const [rows, setRows] = useState<any>([])
  const store = useSelector((state: RootState) => state.vender)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<any>({
    test: 'text',
    Features: [],
  })
  useEffect(() => {
    PackagesApi.getInvoById(
      id,
      (res: any) => {
        setData(res.data)
        setRows([
          {
            amount: data.plan_amount,
            duration: `${data.start_at} - ${data.expire_at}`,
            name: 'subscriptionPlanTable',
          },
          {
            amount:
              data.payment_details && data.payment_details.subtotal
                ? data.payment_details.subtotal
                : '-',
            duration: '',
            name: 'subtotal',
          },
          {
            amount:
              data.payment_details && data.payment_details.tax ? data.payment_details.tax : '-',
            duration: '',
            name: 'tax',
          },
          {
            amount:
              data.payment_details && data.payment_details.total ? data.payment_details.total : '-',
            duration: '',
            name: 'totalAmountDue',
          },
        ])
      },
      setIsLoading
    )
  }, [])
  return (
    <Stack bgcolor={'#fff'} p='24px'>
      <Stack borderBottom={'1px solid #E0E0E0'} p='19px 0'>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack gap='8px'>
            <LogoCloud />
            <Stack>
              <Label text='Konin Cloud SAL' fs='10px' />
              <Label text='Pioneering the Future of Digital Commerce' fs='10px' />
              <Label text='Nassif Yazigi, Beirut, Lebanon' fs='10px' />
              <Label text='Registration No: [Registration Number]' fs='10px' />
            </Stack>
          </Stack>
          <Logo />
        </Stack>
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        gap='15px'
        flexWrap={'wrap'}
        borderBottom={'1px solid #E0E0E0'}
        p='19px 0'
      >
        <Stack gap='8px'>
          <Label text='invoiceDetails' fw='600' fs='12px' />
          <LabelWithValue label='invoiceDate' value={data.invoice_date} />
          <LabelWithValue label='invoiceNumber' value={data.invoice_code} />
        </Stack>
        <Stack gap='8px'>
          <Label text='paymentTerms' fw='600' fs='12px' />
          <LabelWithValue
            label='totalAmountDue'
            value={data.payment_details && data.payment_details.total}
          />
          <LabelWithValue
            label='paymentDueDate'
            value={data.payment_details && data.payment_details.date}
          />
          <LabelWithValue
            label='paymentMethod'
            value={data.payment_details && data.payment_details.method}
          />
        </Stack>
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        gap='15px'
        flexWrap={'wrap'}
        borderBottom={'1px solid #E0E0E0'}
        p='19px 0'
      >
        <Stack gap='8px'>
          <Label text='to' fw='600' fs='12px' />
          <LabelWithValue
            label='seller'
            value={data.seller && data.seller && data.seller.vendor_name}
          />
          <LabelWithValue
            label='sellerID'
            value={data.seller && data.seller && data.seller.vendor_id}
          />
          <LabelWithValue
            label='address'
            value={`${data.seller && data.seller && data.seller.country_name} - ${
              data.seller && data.seller && data.seller.city_name
            }`}
          />
        </Stack>
        <Stack mt='18px' gap='8px'>
          <Label text='' fw='600' fs='12px' />
          <LabelWithValue
            label='mobileNumber'
            value={data.seller && data.seller && data.seller.vendor_mobile}
          />
          <LabelWithValue
            label='email'
            value={data.seller && data.seller && data.seller.vendor_email}
          />
          <LabelWithValue
            label='domainName'
            value={data.seller && data.seller && data.seller.domain}
          />
        </Stack>
      </Stack>
      <Stack overflow={'auto'} width={'100%'} p='19px 0'>
        <Table
          lang={store.lang}
          sx={{minWidth: 547, border: '1px solid #D9D9D9'}}
          aria-label='customized table'
        >
          <TableHead>
            <TableRow>
              <StyledTableCell width='194px'>
                <Label text='description' fs='12px' fw='600' />
              </StyledTableCell>
              <StyledTableCell width='184px'>
                {' '}
                <Label text='duration' fs='12px' fw='600' />
              </StyledTableCell>
              <StyledTableCell>
                {' '}
                <Label text='amount' fs='12px' fw='600' />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any, i: any) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell
                  align={store.isRtl === 0 ? 'left' : 'right'}
                  component='th'
                  scope='row'
                >
                  <Label fs='12px' fw={rows.length === i + 1 ? '600' : '400'} text={row.name} />
                </StyledTableCell>
                {/* <Stack sx={{borderLeft: '1px solid #D9D9D9', borderRight: '1px solid #D9D9D9'}}> */}
                <StyledTableCell align={store.isRtl === 0 ? 'left' : 'right'} width='154px'>
                  <Label fs='12px' fw={rows.length === i + 1 ? '600' : '400'} text={row.duration} />
                </StyledTableCell>
                {/* </Stack> */}
                <StyledTableCell align={store.isRtl === 0 ? 'left' : 'right'}>
                  <Label fs='12px' fw={rows.length === i + 1 ? '600' : '400'} text={row.amount} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
      <Stack gap='5px' p='19px 0'>
        <Label fs='12px' text='ThankYouForChoosingKoninStore' />
        <Label fs='10px' text='weValueYourTrustAndLookForwardToSupportingYourE-commerceSuccess!' />
      </Stack>
    </Stack>
  )
}
