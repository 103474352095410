import {Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import MainBtn from './MainBtn'
import MasgsError from './MasgsError'

export default function SaveButton({
  text,
  submit,
  isLoading,
  setOpen,
  handelselctions,
  errors,
  textCancel,
}: any) {
  const {t} = useTranslation()

  return (
    <Stack mt={'20px'} width={'100%'} gap={'10px'} direction={'row'} justifyContent={'flex-end'}>
      {errors && <MasgsError errors={errors} />}

      <button
        type='submit'
        onClick={() => {
          if (handelselctions) {
            handelselctions()
          }

          setOpen(false)
        }}
        className='btn btn-light me-3'
      >
        {t(textCancel || 'cancel')}{' '}
      </button>
      <MainBtn isLoading={isLoading} width='auto' text={text} type='submit' handelSubmit={submit} />
    </Stack>
  )
}
