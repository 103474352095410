import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import InputType from '../customTable/InputType'
import Vendor from '../../Api/vendor'
import {useDispatch} from 'react-redux'
import {actions} from '../../store/vender/Auth/auth.slice'
import {LoginSchema} from '../../yup/vendorSchema'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import MainBtn from '../customTable/MainBtn'
import ValidationFun from '../../yup/ValidationFun'
import ErrorMsg from '../customTable/NewMsgErr'
import PhoneInput from '../customTable/PhoneInput'
export default function LoginForm({setIsActive}: any) {
  const [userInfo, setUserInfo] = useState<any>({mobile: '0592146780', password: 'ahmad@Nahal12'})
  const [msgErr, setMsgErr] = React.useState<string>('')
  const [isLoading, setUsLoading] = React.useState(false)
  const dispatch = useDispatch()
  const [errors, setErrors] = useState<any>({})
  const handelSubmit = async (e: any) => {
    e.preventDefault()
    ValidationFun({...userInfo, phone_code: value}, LoginSchema, setErrors, (value: any) => {
      Vendor.signin(
        {...userInfo, phone_code: value},
        (res: any) => {
          localStorage.setItem('token', res.token)
          dispatch(actions.login(res.token))

          localStorage.setItem('name', res.full_name)
          localStorage.setItem('logoStore', res.store.logo)
          localStorage.setItem('title', JSON.stringify(res.store.title))
          localStorage.setItem('image', res.profile_image)
          localStorage.setItem('storeImage', res.store.logo)
          localStorage.setItem('domain', res.store.domain)
          localStorage.setItem('tax', res.store.is_tax)
          localStorage.setItem('id', res.id)
          localStorage.setItem('country_id', res.country_id)
          localStorage.setItem('pay', res.pay.is_link_pay)
          localStorage.setItem('currency', res.currency?.symbol)

          setMsgErr('')
        },
        setUsLoading
      )
    })
  }
  const {t} = useTranslation()
  const [value, setValue] = useState('+961')
  const [type, setType] = useState('password')
  const store = useSelector((state: RootState) => state.vender)

  return (
    <form onSubmit={handelSubmit}>
      <Stack gap='20px'>
        <Typography sx={{fontSize: '24px', fontWeight: '600', textAlign: 'center'}}>
          {t('signinTitle')}
        </Typography>

        <PhoneInput
          errors={errors}
          value={value}
          data={userInfo}
          setData={setUserInfo}
          name={'mobile'}
          setValue={setValue}
        />

        <Stack position={'relative'}>
          <InputType
            errors={errors}
            type={type}
            placeholder={t('password')}
            value={userInfo}
            change={setUserInfo}
            name={'password'}
          />
          <Stack
            right={store.isRtl === 0 ? '10px' : 'auto'}
            left={store.isRtl === 1 ? '10px' : 'auto'}
            top='13px'
            position={'absolute'}
          >
            {type !== 'text' ? (
              <VisibilityOffIcon
                onClick={() => {
                  setType('text')
                }}
              />
            ) : (
              <RemoveRedEyeIcon
                onClick={() => {
                  setType('password')
                }}
              />
            )}
          </Stack>
        </Stack>

        <Typography
          onClick={() => {
            setIsActive(1)
          }}
          sx={{fontSize: '13px', color: '#3E97FF', fontWeight: '600', cursor: 'pointer'}}
        >
          {t('forgotPassword')}
        </Typography>
        <MainBtn
          type='submit'
          br='8px'
          bgcolor='#3C3AF7'
          isLoading={isLoading}
          text={'login'}
          handelSubmit={handelSubmit}
          disabled={isLoading}
        />
      </Stack>
    </form>
  )
}
