import React from 'react'
import {Stack} from '@mui/material'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {useTranslation} from 'react-i18next'
// export default function MasgsError({errors}: any) {
//   return (
//     <Stack>
//       <ErrorOutlineIcon />
//     </Stack>
//   )
// }

const options = [
  'Show some love to MUI',
  'Show all notification content',
  'Hide sensitive notification content',
  'Hide all notification content',
]

export default function MasgsError({errors}: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(1)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const {t} = useTranslation()
  return (
    <>
      {Object.keys(errors).length > 0 && (
        <div>
          <List component='nav' aria-label='Device settings'>
            <Stack sx={{cursor: 'pointer'}} onClick={handleClickListItem}>
              <ErrorOutlineIcon
                sx={{
                  color: ' #ef476f',
                }}
              />
            </Stack>
          </List>
          <Menu
            id='lock-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox',
            }}
          >
            {Object.keys(errors).map((key) => (
              <Stack
                sx={{p: '12px', color: 'rgb(239, 71, 111)'}}
                key={errors[key]}
                onClick={(event) => {}}
              >
                {t(errors[key])}
                {` ${key.includes('.') ? `(${key.split('.')[1]})` : ''}`}
              </Stack>
            ))}
          </Menu>
        </div>
      )}
    </>
  )
}
