import {Box, Stack} from '@mui/material'
import React from 'react'
import TitlePage from '../customTable/TitlePage'
import {useTranslation} from 'react-i18next'
import Label from '../customTable/Label'
import PupUpImage from '../customTable/PupUpImage'
import Image from '../customTable/Image'
import MainBtn from '../customTable/MainBtn'
import {useNavigate} from 'react-router-dom'

export default function CurrentTemplate() {
  const {t} = useTranslation()

  const bannerInfo = {
    title: t('template'),
    home: t('home'),
    linkHome: '/',
  }
  const navigate = useNavigate()

  return (
    <Stack>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <TitlePage info={bannerInfo} />

        <Stack gap={'10px'} direction={'row'}></Stack>
      </Stack>
      <Stack
        gap='24px'
        minHeight={'55vh'}
        p='32px'
        style={{marginTop: '30px'}}
        className={`card fds sha`}
      >
        <Stack alignItems={'center'} direction={'row'} gap={'5px'}>
          <Label fs='14px' text='chosenTemplate' />:
          <Label fs='14px' fw='600' text='Food' />
        </Stack>
        <PupUpImage
          image={
            'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg'
          }
        >
          <Box sx={{width: '200px', height: '200px'}}>
            <Image
              sx={{height: '100%', width: '100%', borderRadius: '8px'}}
              src={
                'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg'
              }
            />
          </Box>
        </PupUpImage>
        <MainBtn
          width='fit-content'
          handelSubmit={() => navigate('/templates/list')}
          text='changeTemplate'
        />
      </Stack>
    </Stack>
  )
}
