import {Box, Container, Stack, Typography} from '@mui/material'
import React, {useState, useEffect} from 'react'

import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import iMedia from '../../../interface/iMedia'
import CheckBox from './CheckBox'
import InputType from '../../customTable/InputType'
import {RootState} from '../../../store'
import TabsLangWithFlag from '../../customTable/LangTabs'
import UploadImg from './UpdateImage'
import UploadVideo from './UploadVideo'
import SaveButton from '../../customTable/SaveButton'
import {MediaSchema} from '../../../yup/Media'
import Media from '../../../Api/media'
import {toast} from 'react-toastify'
import Loading from '../../customTable/LoadingForm'
import ValidationFun from '../../../yup/ValidationFun'
import ErrorMsg from '../../customTable/NewMsgErr'

export default function Form({
  rows,
  setRows,
  setOpen,
  open,
  setAdded,
  setNum,
  iloading,
  setIsLoading,
}: any) {
  const {t} = useTranslation()

  const [isCheck, setIsCheck] = useState('image')
  const [media, setMedia] = useState({})
  const [defLang, setDefLang] = useState('')
  const [selectedLang, setSelectedLang] = useState<any>([])
  const [base64Image, setBase64Image] = useState<any>({name: '', size: ''})
  const [video, setVideo] = useState<any>({name: '', size: ''})
  const [msgErr, setMsgErr] = React.useState<string>('')

  const store = useSelector((state: RootState) => state.vender)

  const getLangs = async () => {
    setSelectedLang(store.langs)
    setDefLang(
      store.langs.some((e: any) => e.code === store.defLang) ? store.defLang : store.langs[0].code
    )
  }
  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    getLangs()
  }, [store.langs])
  const saveChange = async () => {
    let data = {...media, attachment: null}
    if (isCheck === 'image') {
      data = {...data, attachment: base64Image}
    } else {
      data = {...data, attachment: video}
    }
    if (data.attachment) {
      ValidationFun(data, MediaSchema, setErrors, (res: any) => {
        const formData = new FormData()
        formData.append('title', res.title)
        formData.append('attachment', res.attachment)
        formData.append('type', isCheck)

        Media.add(
          formData,
          (res: any) => {
            setBase64Image({name: '', size: ''})
            setVideo({name: '', size: ''})
            setMsgErr('')
            setIsLoading(false)
            setOpen(false)
            setNum((prev: any) => prev + 1)
            setRows([res, ...rows])
            setMedia({})
            setAdded(true)
          },
          setIsLoading
        )
      })
    } else {
      setMsgErr('file is required')
    }
  }
  return (
    <Container
      lang={store.lang}
      dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
      sx={{padding: '7px 24px 28px 24px'}}
      maxWidth='md'
    >
      <p style={{width: '500px'}}></p>
      <Stack gap='32px'>
        <CheckBox isCheck={isCheck} setIsCheck={setIsCheck} />
        <Stack position={'relative'} gap='7px'>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              color: 'var(--bs-body-color)',
              lineHeight: '14px',
            }}
          >
            {t('title')}
          </Typography>
          <Box width='100%'>
            <Stack gap='10px'>
              <InputType
                errors={errors}
                name={'title'}
                change={setMedia}
                value={media}
                type='text'
              />

              {/* <Tabs /> */}
            </Stack>
          </Box>
        </Stack>
        <Stack>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              color: 'var(--bs-body-color)',
              lineHeight: '14px',
            }}
          >
            {t('uploadFile')}
          </Typography>
          {!base64Image && !video && (
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '12px',
                color: '#9D9DA6',
                lineHeight: '18px',
              }}
            >
              {t('maxFile')}
            </Typography>
          )}
          {isCheck === 'image' && (
            <Box width='100%'>
              <UploadImg base64Image={base64Image} setBase64Image={setBase64Image} />
              {base64Image.name && (
                <Box
                  sx={{
                    color: '#9D9DA6',
                    padding: '14px',
                    borderRadius: '8px',
                    border: '1px solid var(--bs-gray-300)',
                    marginTop: '12px',
                  }}
                  width={'100%'}
                >
                  {base64Image.name && base64Image.name + ' '}(
                  {base64Image.size && base64Image.size})
                </Box>
              )}
            </Box>
          )}{' '}
          {isCheck === 'video' && (
            <Box width='100%'>
              <UploadVideo base64Image={video} setBase64Image={setVideo} />
              {video.name && (
                <Box
                  sx={{
                    color: '#9D9DA6',
                    padding: '14px',
                    borderRadius: '8px',
                    border: '1px solid var(--bs-gray-300)',
                    marginTop: '12px',
                  }}
                  width={'100%'}
                >
                  {video.name && video.name + ' '}({video.size && video.size})
                </Box>
              )}
            </Box>
          )}
          {base64Image && video && (
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '12px',
                color: '#9D9DA6',
                lineHeight: '18px',
                marginTop: '5px',
              }}
            >
              {t('maxFile')}
            </Typography>
          )}
          <ErrorMsg msgErr={errors['attachment']} />
          <Box width={'100%'} mt='18px'>
            <SaveButton
              text={t('saveChanges')}
              submit={saveChange}
              isLoading={false}
              setOpen={setOpen}
            />
          </Box>
        </Stack>
      </Stack>
      <Stack alignItems={'flex-end'}></Stack>
      {iloading && <Loading />}
    </Container>
  )
}
