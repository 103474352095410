import {Button, Stack} from '@mui/material'
import React from 'react'
import MainBtn from './MainBtn'
import {useNavigate} from 'react-router-dom'
import Label from './Label'
import {useTranslation} from 'react-i18next'

export default function ChangePackagePopUp() {
  const {t} = useTranslation()
  const navigate = useNavigate()
  return (
    <Stack gap='48px' p={{xs: '24px', sm: '60px'}}>
      <Stack gap='16px'>
        <Label align='center' fs='16px' fw='600' text='upgradePlanForMoreFeatures' />
        <Label align='center' fs='16px' text='upgradePlanForMoreFeaturesMsg' />
      </Stack>

      <Button
        sx={{
          fontSize: '14px',
          color: 'white',
          fontWeight: '500',
          background: 'var(--main-color)',
          borderRadius: '6px',
          padding: '12px 20px',
          border: 'none',
          width: '100%',
          minWidth: '120px',
          height: 'auto',
          textTransform: 'none',
          '&:hover': {
            color: '#f16645',
            border: '1px solid #f16645',
          },
        }}
        onClick={() => {
          navigate('/subscriptions-change-package')
        }}
      >
        {t('upgradeNaw')}
      </Button>
    </Stack>
  )
}
