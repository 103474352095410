import {Stack} from '@mui/material'
import React from 'react'
import Label from './Label'
import InputType from './InputType'
import ErrorMsg from './NewMsgErr'

export default function InputWithLabel({
  data,
  setData,
  type,
  placeHolder,
  name,
  fw,
  width,
  text,
  disabled,
  errors,
}: any) {
  console.log(data)

  return (
    <Stack width={width || 'auto'} gap='8px'>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Label fw={fw} text={text} />
      </Stack>
      <InputType
        checked={disabled}
        placeholder={placeHolder || text}
        name={name}
        change={setData}
        value={data}
        type={type}
      />

      {errors && errors[name] && errors && typeof errors[name] === 'string' && (
        <ErrorMsg msgErr={errors[name]} />
      )}
    </Stack>
  )
}
