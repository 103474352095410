import {Stack} from '@mui/material'
import React from 'react'

export default function FixedNumber({num, isNum, fs, fw, color, center}: any) {
  const currency = localStorage.getItem('currency')

  return (
    <Stack
      sx={{
        fontSize: fs || 'auto',
        fontWeight: fw || 'auto',
        color: color || 'auto',
        textAlign: center,
      }}
      width={'max-content'}
    >
      {' '}
      {!isNum && currency} {num && (num % 1 !== 0 ? Number(num).toFixed(2) : num)}
    </Stack>
  )
}
