import {Stack, Typography} from '@mui/material'
import React from 'react'
import {useSelector} from 'react-redux'
import Label from './Label'

export default function SizeImage({width, hight}: any) {
  const {sizeImages} = useSelector((state: any) => state.vender)
  return (
    <>
      {sizeImages[hight] && sizeImages[width] && (
        <Stack
          mt='10px'
          alignItems={'center'}
          justifyContent={'center'}
          gap={'5px'}
          direction={'row'}
          flexWrap={'wrap'}
        >
          <Label color='#a1a1a1' fs='10px' text={'sizeMsg'} />
          <Typography sx={{color: '#a1a1a1'}}>:</Typography>
          <Label
            color='#a1a1a1'
            fs='10px'
            text={`${sizeImages[width]}px x  ${sizeImages[hight]}px`}
          />
        </Stack>
      )}
    </>
  )
}
