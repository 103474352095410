import {Stack} from '@mui/material'
import React, {useState, useEffect} from 'react'
import Cards from './Cards'
import TitlePage from '../../customTable/TitlePage'
import {useTranslation} from 'react-i18next'
import ButtonPrient from './ActionBtn'
import {useParams} from 'react-router'
import OrderApi from '../../../Api/order'
import AddressDetails from './AddressDetails'
import OrderDetails from './OrderDetails'
import Table from './Table'
import Address from '../../../Api/SettingAddress'
import axios from 'axios'
import JwtService from '../../../utils/TokenServices'
import Loading from '../../customTable/LoadingForm'
import SimpleLoading from '../../customTable/SimpleLoading'
import MainBtn from '../../customTable/MainBtn'
import Label from '../../customTable/Label'
import Actions from './Actions'
import Woring from '../../../svg/Woring'
import SelectState from '../../orders/SelectState'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import StatusOrder from '../../orders/StatusOrder'
import PopUp from '../../customTable/PopUp'
import RejectForm from './RejectForm'
import NewStatusOrder from '../../orders/NewStatusOrder'
import NewSelectState from '../../orders/NewSelectState'
import AcceptForm from './AcceptForm'
import FixedNumber from '../../customTable/FixedNumber'

export default function ViewRefundListing() {
  const {t} = useTranslation()
  const {id} = useParams()

  const bannerInfo = {
    title: t('requestDetails'),
    home: t('home'),
    linkHome: '/',
  }
  const [order, setOrder] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const [isLoadingRejectBtn, setIsLoadingRejectBtn] = useState(false)
  const [options, setOptions] = useState([
    {
      value: 'preparing',
      label: t('preparing'),
    },
    {
      value: 'in_progress',
      label: t('inProgress'),
    },

    {
      value: 'completed',
      label: t('completed'),
    },
  ])

  const [changeState, setChangeState] = useState({value: order.status})
  const [isLoadingStatus, setIsLoadingStatus] = useState(false)

  const onchange = (state: any) => {
    OrderApi.putStatus(
      order.id,
      state,
      () => {
        setOrder({...order, status: state})
        setIsLoadingStatus(false)

        if (state !== 'pending') {
          setOptions([
            {
              value: 'preparing',
              label: t('preparing'),
            },
            {
              value: 'in_progress',
              label: t('inProgress'),
            },

            {
              value: 'completed',
              label: t('completed'),
            },
          ])
        }
      },
      setIsLoadingStatus
    )
  }
  const getOrder = async () => {
    const data = await OrderApi.getSingleOrder(
      id,
      (res: any) => {
        setOrder(res.data)
        setChangeState({value: res.data.status})
        if (res.data.status === 'pending') {
          setOptions([
            {
              value: 'pending',
              label: t('pending'),
            },
            {
              value: 'preparing',
              label: t('preparing'),
            },
            {
              value: 'in_progress',
              label: t('inProgress'),
            },

            {
              value: 'completed',
              label: t('completed'),
            },
          ])
        }
      },
      setIsLoading
    )
  }
  const [dataStore, setDataStore] = useState({})
  const [isLoadingAddress, setIsLoadingAddress] = useState(false)
  const getAddress = async () => {
    const data = await Address.get((res: any) => {
      setDataStore(res.data)
    }, setIsLoadingAddress)
  }
  useEffect(() => {
    getOrder()
    getAddress()
  }, [])
  const [isLoadingPdf, setIsLoadingPdf] = useState(false)
  const downloadPdf = async () => {
    setIsLoadingPdf(true)
    const response = await fetch(
      `https://vendor.api.koninstore.com/api/v1/vendor/orders/download-pdf/${id}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/pdf',
          Authorization: `Bearer ${JwtService.getToken()}`,
        },
      }
    )

    if (response.ok) {
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'file.pdf')
      document.body.appendChild(link)
      link.click()
      link.remove()
      setIsLoadingPdf(!true)
    } else {
      setIsLoadingPdf(!true)

      console.error('Failed to download PDF')
    }
  }
  const getPdfInvo = () => {
    OrderApi.getOrderPdf(
      id,
      async (response: any) => {
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file.pdf')
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      setIsLoadingPdf
    )
  }
  const store = useSelector((state: RootState) => state.vender)
  const rejectedStatus = ['completed', 'canceled', 'refunded', 'review_refund', 'partial_refund']
  const statusCancelOrder = ['pending', 'in_progress', 'preparing']
  const [openCancel, setOpenCancel] = useState(false)
  const [openPayment, setOpenPayment] = useState(false)
  return (
    <Stack position={'relative'} minHeight={'80vh'} width={'100%'}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack>
          <Stack gap='15px' flexWrap={'wrap'} direction={'row'}>
            <Stack minWidth={'300px'}>
              <TitlePage info={bannerInfo} />
            </Stack>
            <Stack
              gap='24px'
              mb='34px'
              justifyContent={'flex-end'}
              direction={'row'}
              width={'100%'}
            >
              <Stack height={'100%'} width={'139px'}>
                {rejectedStatus.includes(order.status) ? (
                  <NewStatusOrder text={order.status} state />
                ) : (
                  <>
                    {isLoadingStatus ? (
                      <SimpleLoading />
                    ) : (
                      <NewSelectState
                        check={
                          !(store.permissions && store.permissions.includes('orders_update')) ||
                          rejectedStatus.includes(order.status)
                        }
                        value={{
                          value: 'allOrder',
                          label: t('allOrder'),
                        }}
                        option={options}
                        onchange={onchange}
                        changeState={{value: order.status}}
                        setChangeState={setChangeState}
                        state
                      />
                    )}
                  </>
                )}
              </Stack>{' '}
              {store.permissions && store.permissions.includes('vendor_cancel_order') && (
                <>
                  {statusCancelOrder.includes(order.status) && (
                    <MainBtn
                      handelSubmit={() => setOpenCancel(true)}
                      p='8px 4px'
                      width='auto'
                      text={'cancelOrder'}
                      color='#EF305E'
                      bc='#EF305E'
                      bgcolor='#fff'
                    />
                  )}
                </>
              )}
              {/* <Actions order={order} /> */}
              {/* <ButtonPrient dataStore={dataStore} invoiceData={order} /> */}
              {isLoadingPdf ? (
                <SimpleLoading />
              ) : (
                <button
                  style={{
                    border: 'none',
                    background: '#009EF7',
                    color: 'white',
                    borderRadius: '5.5px',
                    padding: '7px 17px',
                  }}
                  onClick={downloadPdf}
                >
                  {t('downLoad')}
                </button>
              )}
            </Stack>
          </Stack>
          {(order.has_request_refund ||
            order.has_request_partial_refund ||
            order.has_request_cancel) && (
            <Stack gap='16px' mb='32px' borderRadius={'8px'} bgcolor={'#fff'} p='32px'>
              {(order.has_request_refund || order.has_request_partial_refund) &&
                order.status === 'completed' && (
                  <Stack alignItems={'flex-start'}>
                    <Stack
                      p='4px 24px'
                      borderRadius={'32px'}
                      bgcolor={'#FBE8E9'}
                      gap='32px'
                      direction={'row'}
                    >
                      <Stack gap='8px' alignItems={'center'} direction={'row'}>
                        <Woring />
                        <Label
                          fs={{sm: '14px', xs: '10px'}}
                          color='#D22F27'
                          fw='600'
                          text='woringOrder'
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              {order.payment_method !== 'Cash' && (
                <>
                  {' '}
                  {order.status === 'canceled' &&
                    order.cancel_data &&
                    !order.cancel_data.refund_amount && (
                      <Stack alignItems={'flex-start'}>
                        <Stack
                          p='4px 24px'
                          borderRadius={'32px'}
                          bgcolor={'#FBE8E9'}
                          gap='32px'
                          direction={'row'}
                        >
                          <Stack gap='8px' alignItems={'center'} direction={'row'}>
                            <Woring />
                            <Label
                              fs={{sm: '14px', xs: '10px'}}
                              color='#D22F27'
                              fw='600'
                              text='theAmountWasNotRefundedFollowingTheCancellation'
                            />
                          </Stack>
                          {
                            <MainBtn
                              handelSubmit={() => {
                                setOpenPayment(true)
                              }}
                              br='8px'
                              minWidth='auto'
                              width='auto'
                              bgcolor='#D22F27'
                              text='pay'
                            />
                          }
                        </Stack>
                      </Stack>
                    )}
                </>
              )}

              {(order.has_request_refund || order.has_request_partial_refund) && (
                <Stack alignItems={'center'} gap={'5px'} direction={'row'}>
                  <Label fw='600' text='refundType' />:{' '}
                  <Label text={order.has_request_refund ? 'fullRefund' : 'partionRefund'} />
                </Stack>
              )}

              {order.refund_data && order.refund_data.reason && (
                <>
                  {' '}
                  <Label fw='600' text='reasonForRefund' />{' '}
                  <Label
                    color='#7E8299'
                    fs='13px'
                    text={order.refund_data && order.refund_data.reason}
                  />
                </>
              )}
              {order.refund_data && order.refund_data.reject_reason && (
                <>
                  {' '}
                  <Label fw='600' text='rejectReason' />{' '}
                  <Label
                    color='#7E8299'
                    fs='13px'
                    text={order.refund_data && order.refund_data.reject_reason}
                  />
                </>
              )}
              {order.cancel_data && order.cancel_data.reason && (
                <>
                  {' '}
                  <Label fw='600' text='reasonForRefund' />{' '}
                  <Label
                    color='#7E8299'
                    fs='13px'
                    text={order.cancel_data && order.cancel_data.reason}
                  />
                </>
              )}
              {
                <Stack alignItems={'center'} gap={'5px'} direction={'row'}>
                  <Label fw='600' text='RefundAmount' />:{' '}
                  {(order.cancel_data && order.cancel_data.refund_amount) ||
                  (order.refund_data && order.refund_data.refund_amount) ? (
                    <FixedNumber
                      num={
                        (order.cancel_data && order.cancel_data.refund_amount) ||
                        (order.refund_data && order.refund_data.refund_amount) ||
                        0
                      }
                    />
                  ) : (
                    '-'
                  )}
                </Stack>
              }
            </Stack>
          )}

          <Cards order={order} />
          <AddressDetails order={order} />
          <Table order={order} />
          <OrderDetails order={order} />
        </Stack>
      )}
      <PopUp isLoading={isLoadingRejectBtn} width='sm' setOpen={setOpenCancel} open={openCancel}>
        <RejectForm
          setIsLoadingBtn={setIsLoadingRejectBtn}
          isLoadingBtn={isLoadingRejectBtn}
          setOpenReject={setOpenCancel}
          setOrder={setOrder}
          order={order}
        />
      </PopUp>
      <PopUp isLoading={isLoadingBtn} width='sm' setOpen={setOpenPayment} open={openPayment}>
        <AcceptForm
          setIsLoadingBtn={setIsLoadingBtn}
          isLoadingBtn={isLoadingBtn}
          setOpenAccept={setOpenPayment}
          setOrder={setOrder}
          order={order}
        />
      </PopUp>
    </Stack>
  )
}
