import * as React from 'react'
import {pink} from '@mui/material/colors'
import Checkbox from '@mui/material/Checkbox'
import {Stack} from '@mui/material'
import Label from './Label'

const label = {inputProps: {'aria-label': 'Checkbox demo'}}

export default function CheckBoxInput({name, filter, setFilter, value}: any) {
  return (
    <Stack gap='10px' alignItems={'center'} direction={'row'}>
      <Checkbox
        onClick={() => {
          setFilter((prev: any) => {
            return {...prev, [name]: value}
          })
        }}
        {...label}
        checked={filter[name] === value ? true : false}
        sx={{
          color: '#405189',
          padding: '0px',
          '&.Mui-checked': {
            color: '#405189',
          },
        }}
      />
      <Label text={value} />
    </Stack>
  )
}
