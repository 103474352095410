import {FormikProps} from 'formik'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {useLang} from '../../../../../_metronic/i18n/Metronici18n'
import store, {RootState, useDispatch, withToastr} from '../../../../store'
import {manufacturerNameAction} from '../../../../store/vender/manufacturer/thunk'
import {createProductAction, getProductName} from '../../../../store/vender/products/thunk'
import {InitialValues} from '../validationSchema'
import Select, {ActionMeta, SingleValue} from 'react-select'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {schema} from '../../../../yup/ProductsShcema'
import * as Yup from 'yup'
import ErrorMsg from '../../../customTable/ErrorMsg'
import ProductsCata from '../../../../Api/ProductsCata'
import ErrMsg from '../../../customTable/MsgErr'
import {actions} from '../../../../store/vender/Auth/auth.slice'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import Create from '../../../manufacturer/Create'
import MainBtn from '../../../customTable/MainBtn'
import {Stack, Typography} from '@mui/material'
import ValidationFun from '../../../../yup/ValidationFun'
import OldSelect from '../../../customTable/OldSelect'
import {actionsProducts} from '../../../../store/vender/products/slice'
import MasgsError from '../../../customTable/MasgsError'

type Props = {
  formik: FormikProps<InitialValues>
  setActiveKey: React.Dispatch<React.SetStateAction<string>>
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>
  activeKey: string
  setFullActive: any
  setActive: any
}

export const Links = ({
  formik,
  setActiveKey,
  setErrors,
  activeKey,
  errors,
  setActive,
  setMyProduct,
}: any) => {
  const {nameProduct} = useSelector((state: RootState) => state.product)
  const {manufacturerName} = useSelector((state: RootState) => state.manufacturer)

  const dispatch = useDispatch()
  //   isEdit  or  not
  const {id} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const isEditProduct = location.pathname.includes('edit-product')
  const [loading, setLoading] = useState(false)
  const handleSave = async () => {
    if (formik.values) {
      ValidationFun(formik.values, schema, setErrors, () => {
        ProductsCata.add(
          {
            ...formik.values,
            categories: formik.values.categories.map((e: any) => (e = e.value)),
            related_products: formik.values.related_products.map((e: any) => (e = e.value)),
            quantity: formik.values.quantity ? formik.values.quantity : 0,
            cost: formik.values.cost ? formik.values.cost : 0,
          },
          (res: any) => {
            setLoading(false)
            dispatch(actions.setCashData({}))
            dispatch(actionsProducts.setProduct(res))
            setActiveKey('Media')

            setMyProduct(res)
            setActive(7)
            navigate(`/catalog/edit-product/${res.id}?tab=Media`)
            setMsgErr('')
          },
          setLoading
        )
      })
      // schema
      //   .validate(formik.values)
      //   .then((value: any) => {
      //     ProductsCata.add(
      //       {
      //         ...formik.values,
      //         categories: formik.values.categories.map((e: any) => (e = e.value)),
      //         related_products: formik.values.related_products.map((e: any) => (e = e.value)),
      //         quantity: formik.values.quantity ? formik.values.quantity : 0,
      //         cost: formik.values.cost ? formik.values.cost : 0,
      //       },
      //       (res: any) => {
      //         setLoading(false)
      //         dispatch(actions.setCashData({}))
      //         setActiveKey('Media')

      //         setActive(7)
      //         navigate(`/catalog/edit-product/${res.id}?tab=Media`)
      //         setMsgErr('')
      //       },
      //       setLoading
      //     )
      //   })
      //   .catch((error: Yup.ValidationError) => {
      //     setMsgErr(error.message)
      //     setErrorMsg(error.message)
      //     setLoading(false)
      //   })
    } else {
      setMsgErr('Model is required')
    }

    // setActiveKey('Links')
  }
  const [msgErr, setMsgErr] = useState('')
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
    }),
    option: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
      color: 'var(--bs-heading-color, inherit)',
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-app-bg-color)',
      color: 'var(--bs-heading-color, inherit)',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: 'var(--bs-heading-color, inherit)',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'var(--bs-heading-color, inherit)',
    }),
  }
  const lang = useLang()
  const store = useSelector((state: RootState) => state.vender)

  const productListFormat = useMemo(() => {
    const list = nameProduct
      ?.filter((e) => {
        return e.id != id
      })
      .map((item) => {
        return {
          value: item.id,
          label: item.name && (item.name[store.lang] || item.name[store.defLang]),
        }
      })
    return list
  }, [nameProduct, lang])
  const [rows, setRows] = useState([])
  const [newManfacterFormat, setManfacterFormat] = useState([])
  const manfacterFormat: any = useMemo(() => {
    const list = manufacturerName?.map((item: any) => {
      return {
        value: item.id,
        label: item.name && (item.name[store.lang] || item.name[store.defLang]),
      }
    })
    const listNew = rows?.map((item: any) => {
      return {
        value: item.id,
        label: item.name && (item.name[store.lang] || item.name[store.defLang]),
      }
    })

    return [...listNew, ...list]
  }, [manufacturerName, lang, rows])
  useEffect(() => {
    setManfacterFormat(manfacterFormat)
  }, [manufacturerName])
  const fetchNames = useCallback(async () => {
    dispatch(getProductName({}))
    dispatch(manufacturerNameAction({}))
  }, [dispatch])
  //   USEeffect
  useEffect(() => {
    if (activeKey === 'Links') {
      fetchNames()
    }
  }, [fetchNames, activeKey])

  const intl = useIntl()

  const onChangeSelectManufacturer = (
    selectedOption: SingleValue<{
      value: string
      label: string
    }> | null,
    actionMeta: ActionMeta<{
      value: string
      label: string
    }>
  ) => {
    if (selectedOption) {
      formik.setFieldValue('manufacturer_id', selectedOption.value)
    } else {
      formik.setFieldValue('manufacturer_id', '')
    }
  }

  const valueShpeRelatedProduct = useMemo(() => {
    const data = formik.values?.related_products?.map((id: any) => {
      const itemSelected = productListFormat.find((p) => {
        return p.value == id
      })
      return {
        value: id,
        label: itemSelected?.label || '',
      }
    })
    return data
  }, [formik.values?.related_products, productListFormat])
  const {t} = useTranslation()
  const [isAdded, setAdded] = useState(false)
  return (
    <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
      <div className='card card-flush pt-4 mb-4'>
        <div className='card-body pt-0 mt-2'>
          <Stack className='mb-10 fv-row'>
            <label className='form-label'> {t('manufacturers')} </label>
            <OldSelect
              errors={errors}
              name='manufacturer_id'
              value={
                newManfacterFormat.find(
                  (item: any) => item.value === formik.values.manufacturer_id
                ) || null
              }
              onChange={onChangeSelectManufacturer}
              options={newManfacterFormat}
              styles={customStyles}
            />
            <Stack mt='20px'>
              {store.permissions && store.permissions.includes('manufacturers_create') && (
                <Create
                  num={rows.length}
                  setManfacterFormat={setManfacterFormat}
                  rows={rows}
                  setRows={setRows}
                  setAdded={setAdded}
                />
              )}
            </Stack>

            {/* related_products is  array  of  object  need show  errors when  it  toutched   */}
            {formik.touched.manufacturer_id && formik.errors.manufacturer_id && (
              <div className='fv-plugins-message-container'>
                {/* <div className='fv-help-block'>{formik.errors.manufacturer_id[0] as string}</div> */}
              </div>
            )}
          </Stack>

          <div className='mb-10 fv-row'>
            <label className='form-label'> {t('relatedProduct')} </label>
            <OldSelect
              errors={errors}
              name='related_products'
              isMulti
              styles={customStyles}
              value={formik.values.related_products || []}
              onChange={(selectedOption: any, actionMeta: any) => {
                if (selectedOption) {
                  if (id) {
                    formik.setFieldValue(
                      'related_products',
                      selectedOption.map((item: any) => {
                        return item
                      })
                    )
                  } else {
                    formik.setFieldValue(
                      'related_products',
                      selectedOption.map((item: any) => item)
                    )
                  }
                } else {
                  formik.setFieldValue('related_products', [])
                }
              }}
              options={productListFormat as any}
              // placeholder={intl.formatMessage({id: 'Select.related.product'})}
              classNamePrefix='react-select'
            />
            <ErrorMsg msgErr={msgErr} name='At least one related product is required' />

            {/* related_products is  array  of  object  need show  errors when  it  toutched   */}
            {formik.touched.related_products && formik.errors.related_products && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  {/* {formik.errors.related_products?.map(x=>{
                                   return x.value
                              })} */}
                </div>
              </div>
            )}
          </div>

          <Stack
            gap='15px'
            direction={'row'}
            sx={{alignItems: 'center', marginBottom: '25px'}}
            // className='d-flex  mt-4 align-items-center gap-2'
          >
            <Typography fontWeight={'600'} fontSize='14px'>
              {' '}
              {t('featueredProduct')}
            </Typography>
            {/*   switch  */}
            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                checked={!!formik.values.is_featured}
                id='flexSwitchCheckDefault'
                {...formik.getFieldProps('is_featured')}
                onChange={(e) => {
                  formik.setFieldValue(`is_featured`, e.target.checked ? 1 : 0)
                }}
              />
            </div>
          </Stack>
        </div>
      </div>
      <div style={{gap: '20px'}} className='d-flex justify-content-end'>
        {errors && <MasgsError errors={errors} />}

        {/* //<!--begin::Button--> */}
        <button
          onClick={() => {
            setActiveKey('Advanced')
          }}
          className='btn btn-light me-5'
        >
          {t('back')}
        </button>
        {isEditProduct ? (
          <MainBtn
            width='auto'
            type='button'
            handelSubmit={() => {
              setActiveKey('Media')
              navigate(`/catalog/edit-product/${id}?tab=Media`)
            }}
            text='next'
          />
        ) : (
          <MainBtn
            width='auto'
            isLoading={loading}
            type='button'
            handelSubmit={handleSave}
            text='submit'
          />
        )}
      </div>
      <div className='d-flex justify-content-end'>
        <ErrorMsg msgErr={msgErr} name='At least one related product is required' />
        <ErrorMsg msgErr={msgErr} name='' />
      </div>
    </div>
  )
}
