import axios from 'axios'
import Apiservices from '../../lib/ApiServices'
import ApisMethods from './apisMethods'
enum OrderState {
  ALL_ORDER = 'allOrder',
  // Add other order states here
}
const buildQueryString = (params: Record<string, any>): string => {
  return Object.entries(params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')
}
const OrderApi = {
  get: (
    searchText: string,
    rowsPerPage: number,
    page: number,
    state: string,
    callback: any,
    setIsLoading: any,
    filter?: any
  ) => {
    const baseParams = {
      page,
      per_page: rowsPerPage,
    }
    console.log(state, 'state')

    const stateParams = state !== OrderState.ALL_ORDER ? {order_status: state} : {}

    const searchParams = searchText ? {search: searchText} : {}

    const filterParams = filter ? filter : {}

    const queryParams = {
      ...baseParams,
      ...stateParams,
      ...searchParams,
      ...filterParams,
    }

    const url = `/vendor/orders?${buildQueryString(queryParams)}`

    ApisMethods().get(url, callback, setIsLoading)
  },
  getById: (
    rowsPerPage: number,
    page: number,
    id: string | undefined,
    callback: any,
    setIsLoading: any
  ) => {
    let url

    url = `/vendor/customers/orders/${id}?page=${page}&per_page=${rowsPerPage}`
    ApisMethods().get(url, callback, setIsLoading)
    // const coupons = await Apiservices.get(url)

    // return coupons
  },
  putStatus: (id: number | undefined, status: number, callback: any, setIsLoading: any) => {
    ApisMethods().put(
      `/vendor/orders/update-status/${id}`,
      {status: status},
      callback,
      setIsLoading
    )
  },

  getSingleOrder: (id: string | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/orders/${id}`, callback, setIsLoading)
  },
  getOrderPdf: (id: string | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/orders/download-pdf/${id}`, callback, setIsLoading)
  },
  getRefundOrders: (
    searchText: string,
    rowsPerPage: number,
    page: number,
    callback: any,
    setIsLoading: any
  ) => {
    let url

    url = `/vendor/orders/refund?page=${page}&per_page=${rowsPerPage}`
    if (searchText) {
      url = url + `&search=${searchText}`
    }

    ApisMethods().get(url, callback, setIsLoading)
  },
  acceptOrder: (id: number | undefined, data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/orders/refund/${id}/accept`, data, callback, setIsLoading)
  },
  payCanceledOrder: (id: number | undefined, data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(
      `/vendor/orders/refund-money-cancel-order`,
      {...data, order_id: id},
      callback,
      setIsLoading
    )
  },
  canceledOrder: (id: number | undefined, data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/orders/${id}/cancel`, data, callback, setIsLoading)
  },
  rejectOrder: (id: number | undefined, data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/orders/refund/${id}/reject`, data, callback, setIsLoading)
  },
  acceptItem: (id: number | undefined, data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/orders/refund-item/${id}/accept`, data, callback, setIsLoading)
  },
  rejectItem: (id: number | undefined, data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/orders/refund-item/${id}/reject`, data, callback, setIsLoading)
  },
}
export default OrderApi
