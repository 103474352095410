import React, {useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Stack, Typography} from '@mui/material'
import TitlePage from '../customTable/TitlePage'
import {KTSVG} from '../../../_metronic/helpers'
import Table from './Table'
import Create from './Create'
import {useTranslation} from 'react-i18next'
import Filter from '../customTable/Filter'
import Label from '../customTable/Label'
import CheckBoxInput from '../customTable/CheckBoxInput'
import SaveButton from '../customTable/SaveButton'
export default function Orders() {
  const {t} = useTranslation()

  const bannerInfo = {
    title: t('refundedOrders'),
    home: t('home'),
    linkHome: '/',
  }
  const [rows, setRows] = useState<any>([])
  const [filter, setFilter] = useState({})
  const [filterData, setFilterData] = useState({})
  const checkBoxData = ['online', 'cash']
  const [open, setOpen] = useState(false)

  return (
    <Stack>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <TitlePage info={bannerInfo} />
        <Filter
          setFilterData={setFilterData}
          open={open}
          setOpen={setOpen}
          filter={filter}
          setFilter={setFilter}
        >
          <Stack gap='24px' p='24px'>
            <Label text='filter' />
            <Stack gap='16px'>
              <Label text='paymentBy' />
              <Stack gap='31px' direction={'row'}>
                {checkBoxData.map((e: any) => (
                  <CheckBoxInput
                    filter={filterData}
                    name='payment_method'
                    setFilter={setFilterData}
                    value={e}
                  />
                ))}
              </Stack>
            </Stack>
            <SaveButton
              handelselctions={() => {
                setFilter({})
                setFilterData({})
              }}
              textCancel={'reset'}
              text={t('apply')}
              submit={() => {
                setFilter(filterData)
                setOpen(false)
              }}
              isLoading={false}
              setOpen={setOpen}
            />
          </Stack>
        </Filter>
      </Stack>
      <Table filter={filter} rows={rows} setRows={setRows} />
    </Stack>
  )
}
// var(--bs-body-bg)
