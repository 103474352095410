import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink} from '../../../_metronic/layout/core'
import {useTranslation} from 'react-i18next'

import Media from '../storeManagement/media'
import Page from '../storeManagement/page'
import Form from '../storeManagement/page/Form'
import Menu from '../storeManagement/menu'
import FormMenu from '../storeManagement/menu/Form'
import Error403 from '../errors/components/Error403'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import ApplicationMaker from '../storeManagement/applicationMaker'
import WarperBackages from '../customTable/WarperBackages'
import Domain from '../storeManagement/domain'
import CurrentTemplate from './CurrentTemplate'
import TemplatesList from './TemplatesList'

const TemplatesPage = () => {
  const {t} = useTranslation()
  const categoryBreadCrumbs: Array<PageLink> = [
    {
      title: t('home'),
      path: '/storeManagement/media',
      isSeparator: false,
      isActive: false,
    },
  ]
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='current-template'
          element={
            true ? (
              <>
                <CurrentTemplate />

                {/* <PageTitle breadcrumbs={categoryBreadCrumbs}>{t('media')}</PageTitle> */}
              </>
            ) : (
              <Error403 />
            )
          }
        />
        <Route
          path='list'
          element={
            true ? (
              <>
                <TemplatesList />

                {/* <PageTitle breadcrumbs={categoryBreadCrumbs}>{t('media')}</PageTitle> */}
              </>
            ) : (
              <Error403 />
            )
          }
        />
      </Route>
    </Routes>
  )
}

export default TemplatesPage
