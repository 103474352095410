import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import {TransitionProps} from '@mui/material/transitions'
import {Box, Container, IconButton, Stack, Typography} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RedStart from '../../../../customTable/RedStart'
import InputType from '../../../../customTable/InputType'
import LabelInput from '../../../../customTable/LabelInput'
import SaveButton from '../../../../customTable/SaveButton'
import Radio from '@mui/material/Radio'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../store'
import MySelect from '../../../../customTable/CustomSelectTest'
import UploadImage from '../option/UpdateImage'
import Variations from '../../../../../Api/variations'
import {useParams} from 'react-router-dom'
import VariationForm from './VariationForm'
import {useTranslation} from 'react-i18next'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const languages = [
  {label: 'English', lang: 'en', flag: '/media/flags/united-states.svg'},
  {label: 'Arabic', lang: 'ar', flag: '/media/flags/lebanon.svg'},
]
export default function AddVariationsRow({
  open,
  setOpen,
  data,
  setData,
  setAdded,
  options,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  data: any
  setData: any
  setAdded: any
  options: any
}) {
  const [isLoading, setIsLoading] = React.useState(false)

  const handleClose = () => {
    if (!isLoading) {
      setOpen(false)
    }
  }

  return (
    <div>
      <>
        {/* onClick={handleClickOpen} height={'37px'} direction={'row'} width='100%' */}

        {/* <Stack
          width={'801px'}
          sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
        ></Stack>

        <Stack alignItems={'center'} justifyContent={'center'} width={'69px'}>
          <IconButton
            sx={{background: '#4B6FED', borderRadius: '4px', width: '20px', height: '20px'}}
          >
            <AddIcon sx={{color: 'white', padding: '2px'}} />
          </IconButton>
        </Stack> */}
      </>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--bs-body-bg)', // تعديلاتك الخاصة هنا
          },
          '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px', // تعديلاتك الخاصة هنا
          },
        }}
      >
        <VariationForm
          data={data}
          setData={setData}
          open={open}
          setOpen={setOpen}
          setAdded={setAdded}
          options={options}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </Dialog>
    </div>
  )
}
