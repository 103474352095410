import React, {useEffect, useMemo, useState} from 'react'
import 'react-quill/dist/quill.snow.css'
import makeAnimated from 'react-select/animated'
import {ScrollTop} from '../../../../../_metronic/layout/components/scroll-top'
import ScrollToTop from '../../../../componet/scrollTop'
import {isValidLanguages} from '../../../../utils/isAllow'
import * as Yup from 'yup'
import {Advancedschema} from '../../../../yup/ProductsShcema'

import {InputController} from '../componnet/inputController'
import {InputCurtom} from '../componnet/inputCurtom'
import {tabsItem} from '../tabs'
import {InitialValues} from '../validationSchema'
import {useTranslation} from 'react-i18next'
import {Stack} from '@mui/material'
import {InputCurtomWithLabel} from '../componnet/CustomInput'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../store'
import {useSelector} from 'react-redux'
import MainBtn from '../../../customTable/MainBtn'
import ValidationFun from '../../../../yup/ValidationFun'
import OldInput from '../../../customTable/OldInput'
import MasgsError from '../../../customTable/MasgsError'
const animatedComponents = makeAnimated()
type Props = {
  formik: any
  setActiveKey: React.Dispatch<React.SetStateAction<string>>
  setActive: React.Dispatch<React.SetStateAction<number>>
}

export const Advanced = (props: any) => {
  const {formik, setActiveKey, setActive, setErrors, errors} = props
  const {id} = useParams()
  const [languages, setlanguages] = useState(['en'])
  const store = useSelector((state: RootState) => state.vender)

  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])
  const handelNext = async () => {
    if (id) {
      ValidationFun(
        {
          ...formik.values,
          height: formik.values.height || 0,
          length: formik.values.length || 0,
          weight: formik.values.weight || 0,
          width: formik.values.width || 0,
        },
        Advancedschema(languages),
        setErrors,
        () => {
          setMsgErr('')
          setActive(7)
          setActiveKey('Links')
        }
      )
    } else {
      ValidationFun(formik.values, Advancedschema(languages), setErrors, () => {
        setMsgErr('')
        setActive(3)
        setActiveKey('Links')
      })
      // Advancedschema(languages)
      //   .validate(formik.values)
      //   .then((value: any) => {
      //     setMsgErr('')
      //     setActive(3)
      //     setActiveKey('Links')
      //   })
      //   .catch((error: Yup.ValidationError) => {
      //     setMsgErr(error.message)
      //   })
    }

    // setActiveKey('Links')
  }
  const [msgErr, setMsgErr] = useState('')
  const {t} = useTranslation()
  const currency = localStorage.getItem('currency')
  return (
    <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-6'>
      <div className='card card-flush py-4 mb-4'>
        {/* //<!--begin::Card header--> */}
        <div className='card-header'>
          <div className='card-title'>
            <h2>{t('inventory')}</h2>
          </div>
        </div>

        <div className='card-body pt-0'>
          {/* //<!--begin::Input group--> */}
          <div className='mb-10 fv-row'>
            <label className='required form-label'>{t('sku')}</label>
            <Stack flexWrap={{xs: 'wrap', lg: 'nowrap'}} gap='15px' direction={'row'}>
              <Stack minWidth={'200px'} width={{xs: '100%', lg: '78%'}}>
                <OldInput formik={formik} errors={errors} type='number' name={'sku'} />

                {/* <ErrorMsg msgErr={msgErr} name='SKU' /> */}
              </Stack>
              <MainBtn
                height={'44.5px'}
                width='auto'
                without
                handelSubmit={() => {
                  //   GEATE sku RANDAM

                  formik.setFieldValue(`sku`, Math.floor(Math.random() * 10000000))
                }}
                text='generate'
              />
            </Stack>

            {/*   div  with  sam  line  */}
          </div>

          <div
            style={{flexWrap: 'wrap'}}
            className='d-flex justify-content-between align-items-start  mb-2 gap-4 g-2'
          >
            <Stack sx={{width: {lg: '48%', xs: '100%'}}} className=' col-xl-6 fv-row '>
              <label className='required form-label'> {t('inStock')} </label>
              {/*  SELECT  WITH  JUST  YSES  OR  NOR */}
              <select
                className='form-select  form-select-lg fw-bold'
                {...formik.getFieldProps('in_stock')}
              >
                <option value='1'>{t('yes')}</option>
                <option value='0'>{t('no')}</option>
              </select>
            </Stack>
            <Stack sx={{width: {lg: '48%', xs: '100%'}}} className=' col-xl-6 fv-row '>
              <label className='required form-label'>{t('UNLIMITEDSTOCK')}</label>
              {/*  SELECT  WITH  JUST  YSES  OR  NOR */}
              <select
                className='form-select  form-select-lg fw-bold'
                {...formik.getFieldProps('unlimited_quantity')}
              >
                <option value='1'>{t('yes')}</option>
                <option value='0'>{t('no')}</option>
              </select>
            </Stack>
          </div>
          {(formik.values.unlimited_quantity === 0 || formik.values.unlimited_quantity === '0') && (
            <div className='mb-10 mt-4'>
              {/* //<!--begin::Label--> */}
              <label className='form-label'> {t('quantity')} </label>
              {/* //<!--end::Label--> */}
              {/* //<!--begin::Input--> */}
              {/* <input
                type='number'
                {...formik.getFieldProps(`quantity`)}
                className='form-control mb-2'
                placeholder='Quantity'
              /> */}
              <OldInput formik={formik} errors={errors} type='number' name={'quantity'} />

              {formik.touched.quantity &&
                formik.touched.quantity &&
                formik.errors.quantity &&
                formik.errors.quantity && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      {formik.errors.quantity && formik.errors.quantity}
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>

      <div className='card card-flush py-4 my-2'>
        {/* //<!--begin::Card header--> */}
        <div className='card-header'>
          <div style={{display: 'flex', gap: '20px'}} className='card-title'>
            <h2> {t('requiresShipping')} </h2>
            {/*   switch  */}
            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id='flexSwitchCheckDefault'
                checked={!!formik.values.is_shipping_pickup}
                {...formik.getFieldProps('is_shipping_pickup')}
                onChange={(e) => {
                  formik.setFieldValue(`is_shipping_pickup`, e.target.checked ? 1 : 0)
                }}
              />
            </div>
          </div>
          {/*  card  body  */}
        </div>
        <div className='card-body pt-0'>
          {[
            {
              unit: 'kg',
              name: 'weight',
              label: t('weight'),
            },

            {
              unit: 'CM',
              name: 'height',
              label: t('height'),
            },
            {
              unit: 'CM',
              name: 'width',
              label: t('width'),
            },
            {
              unit: 'CM',
              name: 'length',
              label: t('length'),
            },
          ].map((x) => {
            return (
              <>
                <InputCurtomWithLabel
                  errors={errors}
                  key={x.name}
                  required={formik?.values?.is_shipping_pickup}
                  label={x?.label}
                  name={x?.name as keyof InitialValues}
                  unit={x?.unit}
                  formik={formik}
                />
              </>
            )
          })}
        </div>
      </div>

      {/*   proice   */}

      <div className='card card-flush py-2 my-2'>
        {/* //<!--begin::Card header--> */}
        <div className='card-header'>
          <div className='card-title'>
            <h2>{t('pricing')}</h2>
          </div>
        </div>
        <div className='card-body'>
          <InputCurtomWithLabel
            formik={formik}
            label={t('costPrice')}
            type={'number'}
            name={'cost'}
            // placeholder={'Cost Price'}
            required={false}
            unit={currency || '$'}
          />

          <InputCurtomWithLabel
            formik={formik}
            label={t('price')}
            type={'number'}
            errors={errors}
            name={'price'}
            // placeholder={'Cost Price'}
            required={true}
            unit={currency || '$'}
          />

          <InputCurtomWithLabel
            formik={formik}
            label={t('discountPrice')}
            type={'number'}
            name={'discount_amount'}
            errors={errors}
            // placeholder={'Cost Price'}
            required={false}
            unit={currency || '$'}
          />
        </div>
      </div>

      <div style={{gap: '20px'}} className='d-flex justify-content-end'>
        {errors && <MasgsError errors={errors} />}

        <button
          className='btn btn-light me-5'
          onClick={() => {
            setActiveKey('General')
          }}
        >
          {t('back')}
        </button>
        <MainBtn
          width='auto'
          handelSubmit={handelNext}
          isLoading={formik.isSubmitting}
          text='next'
        />
      </div>
      <Stack alignItems={'flex-end'}></Stack>

      <ScrollToTop />
    </div>
  )
}
