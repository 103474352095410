import React from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {useTranslation} from 'react-i18next'
import Address from '../../../Api/SettingAddress'
import {format, parseISO} from 'date-fns'
import {Container, Stack} from '@mui/material'
import Logo from '../../../svg/Logo'
import NameText from '../../customTable/NameText'
import FixedNumber from '../../customTable/FixedNumber'

export default function InvoEn({invoiceData}: any) {
  const store = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()
  console.log(invoiceData)

  return (
    <Container maxWidth='lg'>
      <Stack width='100%' dir={store.isRtl === 0 ? 'ltr' : 'rtl'} className={`container invo `}>
        <div className='head'>
          <div className='flex-c d-block d-sm-none'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <img className='invoice-logo' src={store.StoreImage} alt='invoice-logo' />

              <p className='bold text-center fs-18-med'>
                {invoiceData.store_name && invoiceData.store_name[store.lang]}
              </p>
            </div>
          </div>
          <div className='flex flex-column flex-sm-row'>
            <div className='flex-c'>
              <h2 className='invoice text-center text-sm-start'>{t('invoice')}</h2>
              <p className='bold text-center text-sm-start fs-18-med'>
                {invoiceData.created_at}
                {/* {invoiceData.created_at && format(parseISO(invoiceData.created_at), 'yyyy-MM-dd')} */}
              </p>
              <p className='bold text-center text-sm-start fs-18-med'>
                {t('invoiceID ')}: {invoiceData && invoiceData.order_number}
              </p>
            </div>
            <div className='flex-c d-none d-sm-block'>
              <img className='invoice-logo' src={store.StoreImage} alt='invoice-logo' />
              <p className='bold text-center fs-18-med'>
                {' '}
                {invoiceData.name && invoiceData.name[store.lang]}
              </p>
            </div>
            <div className='flex-c'>
              <h2 className='text-center text-sm-start totalPricing'>
                <Stack alignItems={{xs: 'center', sm: 'flex-start'}}>
                  <FixedNumber num={invoiceData.total} />
                </Stack>
              </h2>
              <p className='bold text-center text-sm-start fs-18-med'>{t('chargedToKonin2Pay')}</p>
              <p className='bold text-center text-sm-start fs-18-med'>
                {t('shippedBy')}: Shipping company name
              </p>
            </div>
          </div>
        </div>
        <div className='sec'>
          <div className='flex align-items-flex-start'>
            <div className='flex-c'>
              <span className='ft-poppins-med text-2d'>{t('from')} :</span>
              <p className='ft-poppins-med text-2d'>
                {invoiceData && invoiceData.vendor && invoiceData.vendor.store_name}
              </p>
              <p className='ft-poppins-med text-2d'>
                {invoiceData && invoiceData.vendor && invoiceData.vendor.store_email}
              </p>
              <p className='ft-poppins-med text-2d'>
                {invoiceData && invoiceData.vendor && invoiceData.vendor.store_mobile}
              </p>
              <p className='ft-poppins-med text-2d max-w-420px'>
                Address : 2972 Westheimer Rd. Santa Ana
              </p>
            </div>
            <div className='flex-c'>
              <span className='ft-poppins-med text-2d'>{t('to')} :</span>
              <p className='ft-poppins-med text-2d'>
                {invoiceData && invoiceData.user && invoiceData.user.full_name}
              </p>
              <p className='ft-poppins-med text-2d'>
                {invoiceData && invoiceData.user && invoiceData.user.email}
              </p>
              <p className='ft-poppins-med text-2d'> {invoiceData && invoiceData.address_phone}</p>
              <p className='ft-poppins-med text-2d max-w-420px'>
                {t('shippingAddress')} : {invoiceData.address_full_name}
              </p>
            </div>
          </div>
        </div>
        <div className='table'>
          <table>
            <thead>
              <tr>
                <th className='text-web fs-18-med  max-w-600px min-w-200px web-auto'>
                  {t('yourOrder')}
                </th>
                <th className='text-web fs-18-med min-w-150px max-w-262px'>{t('sku')}</th>
                <th className='text-web fs-18-med min-w-150px max-w-262px'>{t('quantity')}</th>
                <th className='text-web pl-20 min-w-150px fs-18-med'>{t('unitPrice')}</th>
                <th className='text-web pl-20 min-w-150px fs-18-med'>{t('subTotal')}</th>
                <th className='text-web text-right min-w-150px fs-18-med'>{t('total')}</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData.order_items &&
                invoiceData.order_items.map((e: any, i: any) => (
                  <tr key={i}>
                    <td className='text-left'>
                      <div className='flex justify-content-start align-items-start'>
                        <div className='flex-c'>
                          <span className='product-name ft-poppins-reg'>
                            <NameText text={e.name} />
                          </span>

                          <div className='pl-fr flex-wrap'>
                            {e.product &&
                              e.product.order_options &&
                              e.product.order_options.map((a: any, i: any) => (
                                <p className='ft-poppins-reg'>
                                  {a.option_name && a.option_name[store.lang]}:{' '}
                                  {a.option_value && a.option_value[store.lang]}
                                </p>
                              ))}{' '}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style={{textAlign: 'start'}}>
                      {' '}
                      {t('sku')}: {e.product && e.product.sku}
                    </td>
                    <td style={{textAlign: 'start'}}>{e.bulk_discount_amount} (Bulk Discount)</td>
                    <td style={{textAlign: 'start'}} className='pl-20'>
                      $<span className='bold fs-18px'>{e.unit_price}</span>
                    </td>
                    <td style={{textAlign: 'start'}} className='pl-20'>
                      $<span className='bold fs-18px'>{e.sub_total}</span>
                    </td>
                    <td style={{textAlign: 'start'}} className='text-right'>
                      $<span className='bold fs-18px'>{e.total}</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className='billing'>
          <div className='flex-c billingDetails'>
            <h3 className='bold ft-poppins-med'>{t('billingSummary')}</h3>
            <table className='summaryBorder'>
              <tr>
                <td className='text-gray fs-14px'>{t('subTotal')}</td>
                <td className='fs-15-med padding-left-60px'>
                  <FixedNumber num={invoiceData.sub_total} />
                </td>
              </tr>
              <tr>
                <td className='text-gray fs-14px'>{t('discount')}</td>
                <td className='fs-15-med padding-left-60px'>
                  <Stack direction={'row'} alignItems={'center'} gap='5px'>
                    - <FixedNumber num={invoiceData.total - invoiceData.sub_total} />
                  </Stack>
                </td>
              </tr>
              <tr>
                <td className='text-gray fs-14px'>{t('coupon')}</td>
                <td className='fs-15-med padding-left-60px'>
                  <FixedNumber num={invoiceData.coupon_discount_amount} />
                </td>
              </tr>
              <tr>
                <td className='text-gray fs-14px'>{t('shipping')}</td>
                <td className='fs-15-med padding-left-60px'>
                  <FixedNumber num={invoiceData.shipping_cost} />
                </td>
              </tr>
              <tr>
                <td className='text-gray fs-14px'>{t('tax')}</td>
                <td className='fs-15-med padding-left-60px'>
                  <FixedNumber num={invoiceData.tax} />
                </td>
              </tr>
            </table>
            <div className='flex'>
              <p className='bold'>{t('grandTotal')}</p>
              <p className='bold padding-left-60px'>
                <FixedNumber num={invoiceData.total} />
              </p>
            </div>
          </div>
        </div>
        <div className='footer'>
          <div className='flex-c'></div>
        </div>
      </Stack>
    </Container>
  )
}
