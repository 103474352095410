import {Stack} from '@mui/material'
import React, {useState} from 'react'
import EmptyTaxIcon from '../../../svg/EmptyTaxIcon'
import Label from '../../customTable/Label'
import MainBtn from '../../customTable/MainBtn'
import PopUp from '../../customTable/PopUp'
import EmptyFormTax from './EmptyFormTax'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function EmptyTax({data, setData}: any) {
  const [dataForm, setDataForm] = useState({vendor_type: 'citizen'})
  const [open, setOpen] = useState(false)
  const [isLoading, setisLoading] = useState(false)

  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      minHeight={'653px'}
      width={'100%'}
      bgcolor={'#fff'}
      gap='40px'
    >
      <EmptyTaxIcon />
      <Label text='emptyTax' />
      {store.permissions && store.permissions.includes('vendor_tax_create') && (
        <MainBtn
          handelSubmit={() => {
            setOpen(true)
          }}
          width='auto'
          text='upload'
        />
      )}
      <PopUp isLoading={isLoading} width={'md'} open={open} setOpen={setOpen}>
        <EmptyFormTax
          setisLoading={setisLoading}
          isLoading={isLoading}
          setOpen={setOpen}
          setData={setDataForm}
          data={dataForm}
        />
      </PopUp>
    </Stack>
  )
}
