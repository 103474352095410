import React from 'react'
import {useTranslation} from 'react-i18next'

export default function ErrorMsg({msgErr, name}: {msgErr: string; name: string}) {
  const {t} = useTranslation()
  return (
    <>
      {msgErr.includes(name) && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{t(msgErr)}</div>
        </div>
      )}
    </>
  )
}
