import {CircularProgress, LinearProgress, Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import Product from '../../../../../Api/product'
import Options from '../../../../../Api/options'
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import MainBtn from '../../../../customTable/MainBtn'
interface IOption {
  is_require: number
  is_upload_image: number
  is_main_image: number
  option_id: any
  option: any
  // setIsActiveAddBtn:any
}
export default function OptionBtns({
  setIsCreate,
  setSelectedOption,
  setValues,
  setIsNew,
  optionsBtns,
  setOptionsBtns,
  setLoadingOption,
  setOptionValue,
  isActive,
  setIsActive,
  activeDataBtn,
  isDeleted,
  setIsDeleted,
  activeKey,
  setIsActiveAddBtn,
  setIsAddedOption,
  isAddedOption,
  submit,
  values,
  msgErr,
  setMsgErr,
  isLoading,
  setLoading,
}: {
  setIsCreate: React.Dispatch<React.SetStateAction<any>>
  setSelectedOption: React.Dispatch<React.SetStateAction<never[]>>
  setValues: React.Dispatch<React.SetStateAction<IOption>>
  setIsNew: React.Dispatch<React.SetStateAction<boolean>>
  optionsBtns: {id: number}[]
  setOptionsBtns: React.Dispatch<React.SetStateAction<{id: number}[]>>
  setLoadingOption: React.Dispatch<React.SetStateAction<boolean>>
  setOptionValue: React.Dispatch<React.SetStateAction<never[]>>
  setIsActive: React.Dispatch<React.SetStateAction<number>>
  isActive: number
  activeDataBtn: number
  isDeleted: boolean
  setIsDeleted: any
  activeKey: string
  setIsActiveAddBtn: React.Dispatch<React.SetStateAction<boolean>>
  setIsAddedOption: any
  isAddedOption: any
  submit: any
  values: any
  msgErr: any
  setMsgErr: any
  isLoading: any
  setLoading: any
}) {
  const {id} = useParams()
  const createNewOption = async () => {
    if (isAddedOption) {
      setIsActiveAddBtn(true)
      setIsCreate('')
      setIsActive(-1)
      setValues({
        is_require: 1,
        is_upload_image: 0,
        is_main_image: 1,
        option_id: null,
        option: {},
      })
      setIsNew(false)
      setOptionValue([])
      getAllOption()
    } else {
      submit(setLoading, values, setOptionValue, setMsgErr)
    }
  }
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)

  const getAllOption = async () => {
    Product.getOption(
      id,
      (res: any) => {
        setSelectedOption(res.data)
      },
      setIsLoadingOptions
    )
  }

  useEffect(() => {
    if (!isDeleted && activeKey === 'Options') {
      // getAllOption()
    }
  }, [isDeleted, activeKey])
  const [isLoadingBtns, setLoadingBtns] = useState(false)
  const getOptions = (ID: string | undefined) => {
    setLoadingOption(true)

    Options.getOptionsById(
      ID,
      (res: any) => {
        setLoadingOption(!true)

        if (res.data[0]) {
          setOptionsBtns(res.data)
          // setIsNew(true)
        } else {
          setIsCreate('')
          setOptionsBtns(res.data)
          setIsNew(false)
        }
      },
      setLoadingBtns
    )
  }
  const getOption = (ID: string | undefined, optionId: number) => {
    if (optionId) {
      Options.getOptionById(
        ID,
        optionId,
        (res: any) => {
          setLoadingOption(false)
          setIsCreate(res.data.option)

          setValues({
            ...res.data,
            isDefult: res.data.product_option_values?.find((e: any) => e.is_default === 1)?.id,
            // ?.find((e: any) => e.is_default === 1)?.id,
          })
        },
        setLoadingOption
      )
    }
    // setOptionsBtns(optionsBtns.data.data)
  }
  useEffect(() => {
    if (id && activeKey === 'Options') {
      getOptions(id)
    }
  }, [activeKey])
  const [isFirst, setIsFirst] = useState(false)
  const {t} = useTranslation()

  useEffect(() => {
    setIsDeleted(false)

    if (!isDeleted) {
      if (Array.isArray(optionsBtns) && optionsBtns.length > 0) {
        if (id && optionsBtns[0].id > 0) {
          if (!optionsBtns[activeDataBtn]) {
            getAllOption()
          } else {
            if (optionsBtns.length > 0 && activeDataBtn === 0 && isFirst) {
              getOption(id, optionsBtns[activeDataBtn].id)
            }
            setIsNew(true)
          }
        }
      }
    }
    setIsFirst(true)
  }, [optionsBtns])
  useEffect(() => {
    if (isFirst && optionsBtns[0] && optionsBtns[0].id === -1 && activeKey === 'Options') {
      getAllOption()
    }
  }, [isFirst, activeKey])
  return (
    <Stack flexWrap={'wrap'} mt='33px' gap='16px' direction={'row'}>
      {!isLoadingBtns &&
        optionsBtns.map((e: {id: number}, i: number) => (
          <button
            onClick={() => {
              setIsActive(i)
              getOption(id, optionsBtns[i].id)
              setIsNew(true)
              setOptionValue([])
              setIsAddedOption(true)
            }}
            style={{
              border: 'none',
              background: isActive === i ? 'var(--main-color)' : '#A0A3AF',
              color: 'white',
              borderRadius: '4px',
              padding: '9px 16px 11px 17px',
              fontSize: '14px',
            }}
            key={i}
          >
            {t('option')} {i + 1}
          </button>
        ))}
      {isLoadingBtns && <CircularProgress />}
      <MainBtn
        without
        backageKey={'PRODUCT_OPTION_NUM'}
        num={optionsBtns.length}
        width='auto'
        handelSubmit={createNewOption}
        text='addOption'
      />
    </Stack>
  )
}
