import * as React from 'react'
import Dialog from '@mui/material/Dialog'

import AddForm from './AddForm'
import EditForm from './EditForm'
import ICuopon from '../../../interface/ICuopon'
import SimpleLoading from '../../customTable/SimpleLoading'
import Loading from '../../customTable/LoadingForm'
import {Stack} from '@mui/material'

export default function EditCoupon({
  open,
  setOpen,
  cuopon,
  setEdit,
  edit,
  rows,
  setRows,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  cuopon: ICuopon
  setEdit: React.Dispatch<React.SetStateAction<ICuopon>>
  edit: ICuopon
  rows: any
  setRows: any
}) {
  const handleClickOpen = () => {
    setOpen(true)
  }
  const [isLoading, setIsLoading] = React.useState(false)

  const handleClose = () => {
    if (!isLoading) {
      setOpen(false)
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--bs-body-bg)', // تعديلاتك الخاصة هنا
          },
          '& .MuiDialog-paperWidthSm': {
            maxWidth: '800px', // تعديلاتك الخاصة هنا
          },
        }}
      >
        {isLoading ? (
          <Stack position='relative' width='250px' height={'250px'}>
            <Loading />
          </Stack>
        ) : (
          <EditForm
            rows={rows}
            setRows={setRows}
            edit={edit}
            setEdit={setEdit}
            cuopon={cuopon}
            setOpen={setOpen}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )}
      </Dialog>
    </div>
  )
}
