import {Box, Stack, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import LabelInput from '../../customTable/LabelInput'
import Tabs from '../../customTable/Tabs'
import InputType from '../../customTable/InputType'
import RedStart from '../../customTable/RedStart'
import Card from './Card'
import SeoApi from '../../../Api/Seo'
import RestSaveButton from '../../customTable/RestSaveBtns'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import TabsWithFlag from '../../customTable/LangTabsWithFlag'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import Loading from '../../customTable/LoadingForm'

export default function Seo() {
  const [setting, setSetting] = useState({})

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSeo, setIsLoadingSeo] = useState(false)
  const getSeo = async () => {
    SeoApi.get((res: any) => {
      setSetting(res.data)
      setOld(res.data)
    }, setIsLoadingSeo)
  }
  const [old, setOld] = useState({})

  const handelSubmit = () => {
    SeoApi.put(
      setting,
      (res: any) => {
        setOld(res)
      },
      setIsLoading
    )
  }
  const store = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()

  const [defLang, setDefLang] = useState('')
  const [selectedLang, setSelectedLang] = useState<any>([])
  const getLangs = async () => {
    setSelectedLang(store.langs)
    setDefLang(
      store.langs.some((e: any) => e.code === store.defLang) ? store.defLang : store.langs[0].code
    )
  }
  useEffect(() => {
    getLangs()
  }, [store.langs])
  useEffect(() => {
    getSeo()
  }, [])
  return (
    <>
      {isLoadingSeo ? (
        <Stack position={'relative'} minHeight={'80vh'} width={'100%'}>
          <Loading />
        </Stack>
      ) : (
        <Stack>
          <Stack
            p='24px'
            borderRadius={'8px'}
            bgcolor={'var(--bs-app-header-base-bg-color)'}
            width={'100%'}
          >
            <Stack gap='15px' flexWrap={'wrap'} direction={'row'} justifyContent={'space-between'}>
              <Typography
                sx={{
                  fontSize: '17.5px',
                  color: 'var(--bs-heading-color, inherit)',
                  fontWeight: 600,
                  lineHeight: '16px',
                  textTransform: 'capitalize',
                }}
              >
                {t('seo')}{' '}
              </Typography>{' '}
              <TabsWithFlag
                languages={selectedLang}
                // msgErr={msgErr}
                activeLang={defLang}
                setActiveLang={setDefLang}
              />
            </Stack>
            <Stack gap='20px' mt='24px'>
              <Stack>
                {selectedLang.map((e: any, i: number) => (
                  <>
                    {e.code === defLang && (
                      <Stack gap='20px' flexWrap={'wrap'} direction={'row'}>
                        <Stack width={{sm: '48%', xs: '100%'}} gap='10px'>
                          <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                            <LabelInput text={t('keyword')} />
                          </label>
                          <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                            <InputType
                              name={'home_meta_keywords'}
                              change={setSetting}
                              value={setting}
                              type='text'
                              child={e.code}
                            />
                            {/* <ErrorMsg msgErr={msgErr} name='title' /> */}
                          </Box>
                        </Stack>
                        <Stack width={{sm: '48%', xs: '100%'}} gap='10px'>
                          <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                            <LabelInput text={t('metaTitle')} />
                          </label>
                          <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                            <InputType
                              name={'home_meta_title'}
                              change={setSetting}
                              value={setting}
                              type='text'
                              child={e.code}
                            />
                            {/* <ErrorMsg msgErr={msgErr} name='title' /> */}
                          </Box>
                        </Stack>
                        <Stack width={{sm: '48%', xs: '100%'}} gap='10px'>
                          <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                            <LabelInput text={t('metaDescription')} />
                          </label>
                          <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                            <InputType
                              name={'home_meta_description'}
                              change={setSetting}
                              value={setting}
                              type='text'
                              child={e.code}
                            />
                            {/* <ErrorMsg msgErr={msgErr} name='title' /> */}
                          </Box>
                        </Stack>
                        <Stack width={{sm: '48%', xs: '100%'}} gap='10px'>
                          <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                            <LabelInput text={t('sitemap')} />
                          </label>
                          <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                            <InputType
                              name={'sitemap'}
                              change={setSetting}
                              value={setting}
                              type='text'
                            />
                            {/* <ErrorMsg msgErr={msgErr} name='title' /> */}
                          </Box>
                        </Stack>
                      </Stack>
                    )}
                  </>
                ))}
              </Stack>
              <Stack width={{sm: '48%', xs: '100%'}} gap='10px'>
                <label style={{display: 'flex', gap: '5px'}}>
                  <LabelInput text={t('robotTxt')} />
                </label>
                <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                  <InputType name={'robot_txt'} change={setSetting} value={setting} type='text' />
                  {/* <ErrorMsg msgErr={msgErr} name='title' /> */}
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <Stack mt='24px'>
            <Card
              setting={setting}
              setSetting={setSetting}
              name='facebook_pixel'
              title={t('googletags')}
              subTitle='google tags'
            />
          </Stack>
          <Stack mt='24px'>
            <Card
              setting={setting}
              setSetting={setSetting}
              name='google_tags'
              title='facebook pixel'
              subTitle='facebook pixel'
            />
          </Stack>
          <Stack>
            {store.permissions && store.permissions.includes('seo_update') && (
              <RestSaveButton
                isLoading={isLoading}
                SaveText='Save'
                cancelText='Rest'
                submit={handelSubmit}
                cancel={() => setSetting(old)}
              />
            )}
          </Stack>
        </Stack>
      )}
    </>
  )
}
