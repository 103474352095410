import {Box, Container, Stack, Typography} from '@mui/material'
import React, {useState, useEffect} from 'react'
import UploadImg from '../customTable/UploadImg'
import ErrorMsg from '../customTable/ErrorMsg'
import RedStart from '../customTable/RedStart'
import InputType from '../customTable/InputType'
import MySelect from '../customTable/CustomSelectTest'
import SaveButton from '../customTable/SaveButton'
import Banner from '../../Api/banner'
import IBanner from '../../interface/IBanner'
import * as Yup from 'yup'
import Loading from '../customTable/LoadingForm'
import LabelInput from '../customTable/LabelInput'
import Tabs from '../customTable/Tabs'

import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import TabsWithFlag from '../customTable/LangTabsWithFlag'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {OptionCatalogSchema} from '../../yup/OptionCatalog'
import OptionCatalog from '../../Api/optionCatalog'
import ValidationFun from '../../yup/ValidationFun'
import TabsLangWithFlag from '../customTable/LangTabs'

export default function EditForm({
  setOpen,
  open,
  item,
  setItem,
  setRows,
  rows,
  isLoading,
  setUsLoading,
}: any) {
  const [base64Image, setBase64Image] = React.useState(item.image)
  const [banner, setBanner] = useState({...item})
  const [msgErr, setMsgErr] = React.useState<string>('')
  const {t} = useTranslation()
  useEffect(() => {
    // setBanner({...item, sort: item.sort_order})
  }, [item])
  const [languagess, setlanguagess] = useState(['en'])
  const store = useSelector((state: RootState) => state.vender)

  useEffect(() => {
    setlanguagess(store.storeLangs)
  }, [store.storeLangs])
  const [errors, setErrors] = useState<any>({})

  const saveChange = async () => {
    ValidationFun(
      {
        ...banner,
        sort: banner.sort ? banner.sort : 0,
      },
      OptionCatalogSchema(languagess),
      setErrors,
      (value: any) => {
        OptionCatalog.put(
          item.id,
          {
            ...banner,
            sort: banner.sort ? banner.sort : 0,
          },
          (res: any) => {
            setItem(res)

            setMsgErr('')
            setUsLoading(false)
            setOpen(false)
            setBanner({
              ...res,
            })
            setRows((prev: any) =>
              prev.map((e: any) => {
                if (e.id === item.id) {
                  return {
                    ...res,
                  }
                } else {
                  return e
                }
              })
            )
          },
          setUsLoading
        )
      }
    )
    // OptionCatalogSchema(languagess)
    //   .validate({
    //     ...banner,
    //     sort: banner.sort ? banner.sort : 0,
    //   })
    //   .then(() => {
    //     OptionCatalog.put(
    //       item.id,
    //       {
    //         ...banner,
    //         sort: banner.sort ? banner.sort : 0,
    //       },
    //       (res: any) => {

    //         setItem(res)

    //         setMsgErr('')
    //         setUsLoading(false)
    //         setOpen(false)
    //         setBanner({
    //           ...res,
    //         })
    //       },
    //       setUsLoading
    //     )
    //   })
    //   .catch((error: Yup.ValidationError) => {
    //     setMsgErr(error.message)
    //     setUsLoading(false)
    //   })
  }

  const [defLang, setDefLang] = useState('')
  const [selectedLang, setSelectedLang] = useState<any>([])
  const getLangs = async () => {
    setSelectedLang(store.langs)
    setDefLang(
      store.langs.some((e: any) => e.code === store.defLang) ? store.defLang : store.langs[0].code
    )
  }
  useEffect(() => {
    getLangs()
  }, [store.langs])
  return (
    <Container
      lang={store.lang}
      dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
      sx={{padding: '7px 24px 28px 24px'}}
      maxWidth='md'
    >
      <p style={{width: '500px'}}></p>
      <Typography sx={{fontSize: '20px', fontWeight: '600', color: 'var(--bs-body-color)'}}>
        {t('editoptionCatalog')}
      </Typography>

      <Stack mt='24px' position={'relative'} width={{xs: '100%'}} gap='10px'>
        {selectedLang.map((e: any, i: number) => (
          <>
            {e.code === defLang && (
              <>
                <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                  <LabelInput text={t('name')} />
                  <RedStart />
                </label>
                <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                  <InputType
                    name={'name'}
                    change={setBanner}
                    value={banner}
                    type='text'
                    child={e.code}
                    errors={errors}
                  />
                  {/* <ErrorMsg msgErr={msgErr} name='address' /> */}
                </Box>
              </>
            )}
          </>
        ))}
        <Stack
          position={'absolute'}
          right={store.isRtl === 0 ? '0' : 'auto'}
          left={store.isRtl === 1 ? '0' : 'auto'}
          top='24px'
        >
          <TabsLangWithFlag
            activeLang={defLang}
            setActiveLang={setDefLang}
            languages={selectedLang}
            name={'name'}
            errors={errors}
          />
        </Stack>
      </Stack>
      <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
        <Stack direction={'row'} gap='5px'>
          <label style={{display: 'flex', gap: '5px'}}>
            <LabelInput text={t('sort')} />
          </label>
        </Stack>
        <InputType name={'sort'} change={setBanner} value={banner} type='number' />
        <ErrorMsg msgErr={msgErr} name='sort' />
      </Stack>
      <SaveButton text={t('saveChanges')} submit={saveChange} isLoading={false} setOpen={setOpen} />
      <Stack alignItems={'flex-end'}>
        <ErrorMsg msgErr={msgErr} name='' />
      </Stack>
      {isLoading && <Loading />}
    </Container>
  )
}
